import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DATE_STANDARD_FORMAT } from 'constants/date';
import { IFilterDate, ITreeNodeCheckbox } from 'interfaces/erms';
import moment from 'moment';
import { RootState } from 'store/store';

interface ITrigger {
  isCallUnlinkedHarvestTransactionTable: boolean;
  isCallWeighbridgeTransactionTable: boolean;
  isCallLinkedHarvestTransactionTable: boolean;
  isCallAllWeighbridgeTransaction: boolean;
}

interface IErms {
  filterDate: IFilterDate;
  trigger: ITrigger;
  treeData: ITreeNodeCheckbox[];
}

const initialState: IErms = {
  filterDate: {
    startDate: moment().startOf('month').format(DATE_STANDARD_FORMAT),
    endDate: moment().format(DATE_STANDARD_FORMAT),
  },
  trigger: {
    isCallUnlinkedHarvestTransactionTable: false,
    isCallWeighbridgeTransactionTable: false,
    isCallLinkedHarvestTransactionTable: false,
    isCallAllWeighbridgeTransaction: false,
  },
  treeData: [],
};

export const ermsSlice = createSlice({
  name: 'erms',
  initialState: initialState,
  reducers: {
    changeFilterDateRange: (state, action: PayloadAction<IFilterDate>) => {
      state.filterDate = action.payload;
    },
    changeTriggerCallTransactionTable: (state, action: PayloadAction<Partial<ITrigger>>) => {
      state.trigger = { ...state.trigger, ...action.payload };
    },
  },
});

export const { changeFilterDateRange, changeTriggerCallTransactionTable } = ermsSlice.actions;

export const ermsSelector = (state: RootState) => state.erms;

export default ermsSlice.reducer;
