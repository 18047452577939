import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LoopIcon from '@mui/icons-material/Loop';
import { Box, Button, CircularProgress, Grid, IconButton, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import {
  FILE_TYPE,
  FILE_TYPE_GEOJSON_INTERVAL_FILE,
  LABEL_DATA_RASTERS,
  LayerTypeEnum,
  LIST_FILE_RASTER,
} from 'common/defines/constants';
import { ClientActionsDialog } from 'components/ClientsInfo/ClientActionsDialog';
import { layerTypeListImages } from 'components/Dashboard/AnalyticsConfig/Form/Co2SequestrationForm';
import { QUERY_KEY } from 'constants/constants';
import saveAs from 'file-saver';
import { useCheckRTL } from 'hooks/common/useCheckRLF';
import JSZip from 'jszip';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  deleteIntervalAnalysisData,
  deleteRasterAnalysisData,
  getDownloadFileRasterLayer,
  getDownloadLines,
  getDownloadStreamlineOrder,
  getDownloadTreeTiltFallen,
  getDownloadZipFileAnalytics,
  postUploadZipFileAnalytics,
  uploadLineStringData,
  uploadRasterImageData,
  uploadStreamLineOrderData,
  uploadTreeTiltFallenData,
  uploadVectorContourData,
} from 'services/analytics/apiAnalyticsConfig.services';
import { uploadMedia } from 'services/media';
import { SelectFileErrorModal } from './SelectFileErrorModal';

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: '350px !important',
  height: '2.652em !important',
  minHeight: '2.652em !important',
  color: theme.palette.color.grey2,
  border: `1px solid ${theme.palette.color.grey2}`,
  borderRadius: '5px',
  textTransform: 'none',
  ':disabled': {
    opacity: theme.palette.action.disabledOpacity,
  },
}));

const vectorLayerTypeList = [
  LayerTypeEnum.VECTOR_CONTOUR,
  LayerTypeEnum.STREAM_LINE_ORDER,
  LayerTypeEnum.TREE_TILT_FALLEN,
  LayerTypeEnum.LINES,
];

const vectorLayerTypeListWithoutContour = vectorLayerTypeList.slice(1);

interface ISelectFile {
  onSelectFile: (file: any) => void;
  acceptFile: string | RegExp;
  fileSelected: any;
  disabled?: boolean;
  analysisId?: string;
  layerType?: LayerTypeEnum;
  fileType?: FILE_TYPE;
  deleteFileGeojson: (id: string, dataType?: string) => Promise<AxiosResponse<any, any>>;
}

const SelectFile = ({
  onSelectFile,
  acceptFile,
  fileSelected,
  disabled,
  analysisId,
  layerType,
  fileType,
  deleteFileGeojson,
}: ISelectFile) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string | { error: string }[]>('');
  const theme = useTheme();
  const queryClient = useQueryClient();

  const uploadZipFileAnalytics = useMutation(postUploadZipFileAnalytics, {
    onSuccess: () => {
      toast.success(`Upload ${LABEL_DATA_RASTERS[layerType!]} File Successful`, { toastId: 1 });
    },
    onError: () => {
      onSelectFile(null);
      toast.error(`Upload ${LABEL_DATA_RASTERS[layerType!]} File Error`, { toastId: 2 });
    },
  });

  const callVectorApi = (data: FormData) => {
    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }
    switch (layerType) {
      case LayerTypeEnum.VECTOR_CONTOUR:
        return uploadVectorContourData(data);
      case LayerTypeEnum.TREE_TILT_FALLEN:
        return uploadTreeTiltFallenData(data);
      case LayerTypeEnum.LINES:
        return uploadLineStringData(data);
      case LayerTypeEnum.STREAM_LINE_ORDER:
      default:
        return uploadStreamLineOrderData(data);
    }
  };

  const uploadVectorMutation = useMutation((data: FormData) => callVectorApi(data), {
    onSuccess: () => {
      toast.success(`Upload ${LABEL_DATA_RASTERS[layerType!]} File Successful`, { toastId: 1 });
      queryClient.invalidateQueries([QUERY_KEY.RASTER_DETAILS]);
    },
    onError: (error: any) => {
      setErrorMessages(error.data?.message);
      setIsOpenErrorModal(true);
      onSelectFile(null);
      toast.error(`Upload ${LABEL_DATA_RASTERS[layerType!]} File Error`, { toastId: 2 });
    },
  });

  const uploadRasterImageMutation = useMutation(uploadRasterImageData, {
    onSuccess() {
      toast.success(`Upload ${LABEL_DATA_RASTERS[layerType!]} File Successful`, { toastId: 1 });
      queryClient.invalidateQueries([QUERY_KEY.RASTER_DETAILS]);
    },
    onError: (error: any) => {
      setErrorMessages(error.data?.message);
      setIsOpenErrorModal(true);
      onSelectFile(null);
      toast.error(`Upload ${LABEL_DATA_RASTERS[layerType!]} File Error`, { toastId: 2 });
    },
  });

  const handleDownloadFileAnalytics = useMutation(
    () => getDownloadZipFileAnalytics(fileSelected.analysisId, fileType!),
    {
      onSuccess: (res: any) => {
        setIsDownloading(false);
        if (res.headers.filename.includes('.zip')) {
          const zip = new JSZip();
          const resData = res.data;
          Object.keys(resData).map((item) => {
            const blob = new Blob([JSON.stringify(resData[item])], { type: 'text/plain;charset=utf-8' });
            zip.file(`${item}.geojson`, blob);
            return item;
          });
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, res.headers.filename);
          });
        } else {
          const blob = new Blob([JSON.stringify(res.data)], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, res.headers.filename);
        }

        toast.success(`Download File Successful`, { toastId: 1 });
      },
      onError: () => {
        setIsDownloading(false);
        toast.error(`Download File Error`, { toastId: 2 });
      },
      onMutate: () => setIsDownloading(true),
    }
  );

  const handleDownloadFileRasterLayer = useMutation(() => getDownloadFileRasterLayer(fileSelected._id), {
    onSuccess: (res: any) => {
      setIsDownloading(false);
      const zip = new JSZip();
      const fileList = res.data.files;
      fileList.forEach((file: any) => {
        if (file.name.charAt(0) === '/') {
          zip.file(file.name.slice(1), file.data.data);
        } else {
          zip.file(file.name, file.data.data);
        }
      });
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, res.data.zipName);
      });
      toast.success(`Download File Successful`, { toastId: 1 });
    },
    onError: () => {
      setIsDownloading(false);
      toast.error(`Download File Error`, { toastId: 2 });
    },
    onMutate: () => setIsDownloading(true),
  });

  const switchDownloadVectorFileWithoutContour = (analysisId: string) => {
    switch (layerType) {
      case LayerTypeEnum.STREAM_LINE_ORDER:
        return getDownloadStreamlineOrder(analysisId);
      case LayerTypeEnum.LINES:
        return getDownloadLines(analysisId);
      case LayerTypeEnum.TREE_TILT_FALLEN:
      default:
        return getDownloadTreeTiltFallen(analysisId);
    }
  };

  const downloadVectorFileWithoutContourMutation = useMutation(switchDownloadVectorFileWithoutContour, {
    onSuccess(res) {
      const blob = new Blob([JSON.stringify(res.data)], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, res.headers.filename);
      toast.success(`Download File Successful`, { toastId: 1 });
      setIsDownloading(false);
    },
    onMutate: () => setIsDownloading(true),
  });

  const handleDownloadVectorFileWithoutContour = () => {
    analysisId && downloadVectorFileWithoutContourMutation.mutate(analysisId);
  };

  const handleDownloadFile = () => {
    if (layerType && vectorLayerTypeListWithoutContour.includes(layerType)) {
      handleDownloadVectorFileWithoutContour();
    } else {
      if (fileType && fileSelected.analysisId) {
        handleDownloadFileAnalytics.mutate();
      } else {
        handleDownloadFileRasterLayer.mutate();
      }
    }
  };

  const onCloseErrorModal = () => {
    setIsOpenErrorModal(false);
    setErrorMessages('');
  };

  const handleUploadRasterImage = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await uploadMedia(formData);
    const { name, fileSize, path } = response.data;
    uploadRasterImageMutation.mutate({ name, size: fileSize, path, analysisId: analysisId!, layerType: layerType! });
  };

  const { open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (files: File[]) => {
      const regex = new RegExp(acceptFile);
      if (regex.test(files[0].name)) {
        if (analysisId && layerType) {
          const formData = new FormData();
          if (vectorLayerTypeList.includes(layerType)) {
            formData.append('file', files[0]);
            formData.append('sensorId', analysisId);
            onSelectFile(files[0]);
            uploadVectorMutation.mutate(formData);
          } else if (layerTypeListImages.includes(layerType)) {
            handleUploadRasterImage(files[0]);
          } else {
            formData.append('analysisId', analysisId);
            formData.append('layerType', layerType);
            formData.append('file', files[0]);
            uploadZipFileAnalytics.mutate(formData);
          }
        }
        onSelectFile(files[0]);
      } else {
        toast.error('Drop file failed', { toastId: 1 });
      }
    },
  });

  const formatBytes = useMemo(() => {
    const bytes = fileSelected?.size;
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }, [fileSelected]);

  const getDetail = useMemo(() => {
    if (!fileSelected) {
      return <></>;
    }

    return (
      <>
        <Grid>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}>
            {fileSelected._id ? fileSelected.name : fileSelected.path}
          </Typography>
        </Grid>
        <Grid>
          <Typography color="disabled" sx={{ fontSize: '12px' }}>
            {`${moment(fileSelected._id ? fileSelected.createdAt : fileSelected.lastModifiedDate).format(
              'YYYY-MM-DD'
            )}, ${formatBytes}`}
          </Typography>
        </Grid>
        <Grid>
          {fileSelected.userCreated?.name && (
            <Typography color="disabled" sx={{ fontSize: '12px' }}>
              {`Upload by ${fileSelected.userCreated.name}`}
            </Typography>
          )}
        </Grid>
      </>
    );
  }, [formatBytes, fileSelected]);

  const renderLoopIcon = (
    <LoopIcon
      style={{
        color: theme.palette.color.green5,
        fontSize: '28px',
      }}
      sx={{
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(0deg)',
          },
        },
      }}
    />
  );

  const mutationDeleteGeojsonFile = useMutation((id: string) => deleteFileGeojson(id, fileType), {
    onSuccess: () => {
      toast.success('Delete file successfully');
      queryClient.invalidateQueries([QUERY_KEY.RASTER_DETAILS]);
      setOpenDeleteModal(false);
    },
    onError: () => {
      toast.error('Error deleting file');
      setOpenDeleteModal(false);
    },
  });

  const mutationDeleteIntervalFile = useMutation((id: string) => deleteIntervalAnalysisData(id), {
    onSuccess: () => {
      toast.success('Delete file successfully');
      queryClient.invalidateQueries([QUERY_KEY.RASTER_DETAILS]);
      setOpenDeleteModal(false);
    },
    onError: () => {
      toast.error('Error deleting file');
      setOpenDeleteModal(false);
    },
  });

  const mutationDeleteRasterFile = useMutation((id: string) => deleteRasterAnalysisData(id, layerType), {
    onSuccess: () => {
      toast.success('Delete file successfully');
      queryClient.invalidateQueries([QUERY_KEY.RASTER_DETAILS]);
      setOpenDeleteModal(false);
    },
    onError: () => {
      toast.error('Error deleting file');
      setOpenDeleteModal(false);
    },
  });

  const handleDeleteFile = () => {
    if (analysisId) {
      if (layerType) {
        const isRasterFile = LIST_FILE_RASTER.includes(layerType);
        if (isRasterFile) {
          mutationDeleteRasterFile.mutate(analysisId);
        }
      } else if (fileType === FILE_TYPE.INTERVALS_FILE) {
        mutationDeleteIntervalFile.mutate(analysisId);
      } else {
        mutationDeleteGeojsonFile.mutate(analysisId);
      }
    }
  };

  const renderSelectFileName = () => {
    const regex = new RegExp(acceptFile);
    if (regex.test('.zip')) {
      return t('trans.select_zip_file');
    } else if (regex.test('.mp4.m4v.mkv.mov.mpg.wmv')) {
      return t('trans.select_video_file');
    } else if (regex.test('.png.jpg.tiff')) {
      return t('trans.select_image_file');
    } else if (regex.test('.xlsx')) {
      return t('trans.select_excel_file');
    } else {
      return t('trans.select_file');
    }
  };

  const { isRTL } = useCheckRTL();

  return (
    <>
      {fileSelected ? (
        <Grid container alignItems="center" sx={{ width: '350px' }}>
          <Grid item xs={2} sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '4px',
                [isRTL ? 'right' : 'left']: '-28px',
                cursor: 'pointer',
              }}>
              {isDownloading ? (
                renderLoopIcon
              ) : (
                <Tooltip title="Click to download" placement="top" onClick={() => handleDownloadFile()}>
                  <FileDownloadOutlinedIcon
                    style={{
                      color: theme.palette.color.green5,
                      fontSize: '28px',
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <InsertDriveFileIcon fontSize="large" color="info" />
          </Grid>
          <Grid item xs={8}>
            {getDetail}
          </Grid>
          <Grid item xs={2} sx={{ position: 'relative' }}>
            {!disabled && !uploadZipFileAnalytics.isLoading && (
              <IconButton>
                <ClearOutlinedIcon color="disabled" onClick={() => setOpenDeleteModal(true)} />
              </IconButton>
            )}
            {(uploadVectorMutation.isLoading || uploadZipFileAnalytics.isLoading) && (
              <IconButton>
                <CircularProgress size={22} thickness={4} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box display={'flex'}>
          <ButtonStyled children={renderSelectFileName()} onClick={open} disabled={disabled} />
          {uploadVectorMutation.isLoading && (
            <IconButton>
              <CircularProgress size={22} thickness={4} />
            </IconButton>
          )}
        </Box>
      )}
      <ClientActionsDialog
        title={
          fileType && FILE_TYPE_GEOJSON_INTERVAL_FILE.includes(fileType)
            ? t('trans.delete_file_(interval)')
            : t('trans.delete_file')
        }
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        description={t('trans.are_you_sure_about_that')}
        onCancel={() => setOpenDeleteModal(false)}
        onSave={handleDeleteFile}
        submit={false}
      />
      <SelectFileErrorModal
        title="Error Upload"
        open={isOpenErrorModal}
        onClose={onCloseErrorModal}
        errorMessage={errorMessages}
        onCancel={onCloseErrorModal}
        submit={false}
      />
    </>
  );
};

export default SelectFile;
