import { QUERY_KEY } from 'constants/constants';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryMe } from 'services/clients/apiClient.services';

const useQueryMe = () => {
  const { data: userInfo, isLoading: loadingMe } = useQuery(QUERY_KEY.CHECK_SUPER_ADMIN, () => queryMe());

  const isSuperAdmin = useMemo(() => {
    return userInfo?.data?.isSuperAdmin;
  }, [userInfo]);
  const isSuperUser = useMemo(() => {
    return userInfo?.data?.isSuperUser;
  }, [userInfo]);

  const userId = useMemo(() => {
    if (!userInfo) return '';
    return userInfo.data._id;
  }, [userInfo]);

  return { userId, isSuperAdmin, isSuperUser, loadingMe };
};

export default useQueryMe;
