import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IMetadata } from 'common/defines/clients';
import { generateListCheckedLastLevelIds } from 'common/utils/erms';
import PaginationCommon from 'components/Common/PaginationCommon';
import { QUERY_KEY } from 'constants/constants';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import {
  ICreateHarvestLinkRequest,
  IHarvestResponseData,
  IOption,
  ITreeNodeCheckbox,
  LinkStatusEnum,
} from 'interfaces/erms';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { createHarvestLinkTag } from 'services/analytics/apiAnalyticsConfig.services';
import { getHarvestTableData } from 'services/erms';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeTriggerCallTransactionTable, ermsSelector } from 'store/slices/ermsSlice';
import CommonContainer from '../components/CommonContainer';
import ConfirmTagModal from '../components/ConfirmTagModal';
import CustomTableCell from '../components/CustomTableCell';

interface UnlinkedHarvestTransactionTableProps {
  treeData: ITreeNodeCheckbox[];
  listTagOptions: IOption[];
}

const UnlinkedHarvestTransactionTable: FC<UnlinkedHarvestTransactionTableProps> = ({ treeData, listTagOptions }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpenModalTag, setIsOpenModalTag] = useState<boolean>(false);
  const [unlinkedHarvestTableData, setUnlinkedHarvestTableData] = useState<IHarvestResponseData[]>([]);
  const [unlinkedHarvestMetadata, setUnlinkedHarvestMetadata] = useState<IMetadata>();

  const [selectedTag, setSelectedTag] = useState<ICreateHarvestLinkRequest>();

  const getListCheckedLastLevelIds = generateListCheckedLastLevelIds(treeData);

  const { filterDate, trigger } = useAppSelector(ermsSelector);

  const unlinkHarvestTransactionRequest = useMemo(() => {
    return {
      fromDate: moment(filterDate.startDate).utc(true).toISOString(),
      toDate: moment(filterDate.endDate).utc(true).toISOString(),
      currentPage: currentPage,
      levelIds: getListCheckedLastLevelIds,
      linkStatus: LinkStatusEnum.UNLINK,
      perPage: 10,
    };
  }, [currentPage, filterDate.endDate, filterDate.startDate, getListCheckedLastLevelIds]);

  const { refetch: refetchUnlinkTransaction } = useQuery(
    [QUERY_KEY.GET_UNLINKED_HARVEST_TRANSACTION_TABLE, currentPage],
    () => getHarvestTableData(unlinkHarvestTransactionRequest),
    {
      enabled: !!getListCheckedLastLevelIds.length,
      retry: 0,
      onSuccess(res) {
        setUnlinkedHarvestMetadata(res.metadata as IMetadata);

        const initialHarvestTableData = (res.docs as IHarvestResponseData[])?.map((item) => {
          return { ...item, tags: '' };
        });
        setUnlinkedHarvestTableData(initialHarvestTableData);
      },
      onError() {
        setUnlinkedHarvestTableData([]);
        setUnlinkedHarvestMetadata(undefined);
      },
    }
  );

  useEffect(() => {
    const { isCallUnlinkedHarvestTransactionTable } = trigger;
    if (isCallUnlinkedHarvestTransactionTable) {
      refetchUnlinkTransaction();
      dispatch(changeTriggerCallTransactionTable({ isCallUnlinkedHarvestTransactionTable: false }));
    }
  }, [dispatch, refetchUnlinkTransaction, trigger]);

  const createHarvestLinkTagMutation = useMutation(createHarvestLinkTag, {
    onSuccess: () => {
      setIsOpenModalTag(false);
      setSelectedTag(undefined);
      refetchUnlinkTransaction();
      toast.success('Transaction linked successfully');
    },
    onError: (error) => {
      console.error('Error calling createLink:', error);
    },
  });

  const handleCloseConfirmModal = () => {
    setIsOpenModalTag(false);
    setSelectedTag(undefined);
  };

  const handleClickYes = () => {
    if (!selectedTag) {
      console.error('Missing selectedHarvestId or selectedTag');
      return;
    }
    createHarvestLinkTagMutation.mutate(selectedTag);
  };

  const handleChangeTags = (event: SelectChangeEvent, item: IHarvestResponseData) => {
    const value = event.target.value as string;
    const tagContent = listTagOptions.find((item) => item.value === value)?.label ?? '';

    if (value) {
      setIsOpenModalTag(true);
      setSelectedTag({ harvestId: item._id, tag: tagContent, weighbridgeId: value });
    }
  };

  const renderLinkWithTags = (item: IHarvestResponseData) => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={''}
          onChange={(e: any) => handleChangeTags(e, item)}
          size="small">
          {listTagOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <CommonContainer style={{ px: 3, py: 1 }}>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography component="h1" fontSize="20px" fontWeight={500}>
          {t('erms.unlinked_harvest_transaction')}
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
        <Scrollbars autoHeight autoHeightMax="500px" autoHide>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <CustomTableCell>{t('erms.harvest_date')}</CustomTableCell>
                <CustomTableCell>{t('erms.estate_name')}</CustomTableCell>
                <CustomTableCell>{t('erms.ripe')}</CustomTableCell>
                <CustomTableCell>{t('erms.over_ripe')}</CustomTableCell>
                <CustomTableCell>{t('erms.under_ripe')}</CustomTableCell>
                <CustomTableCell>{t('erms.driver_name')}</CustomTableCell>
                <CustomTableCell>{t('erms.vehicle_number')}</CustomTableCell>
                <CustomTableCell>{t('erms.link_with_tags')}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unlinkedHarvestTableData?.map((item, index) => {
                const {
                  hvDate,
                  estateName,
                  divisionName,
                  blockName,
                  hvRipe,
                  hvOverripe,
                  hvUnderripe,
                  driverName,
                  vehicleNumber,
                } = item;
                return (
                  <TableRow key={index}>
                    <TableCell sx={{ minWidth: '110px' }}>{moment(hvDate).format(DATE_VISUALIZE_FORMAT)}</TableCell>
                    <TableCell>{`${estateName}${divisionName ? `/${divisionName}` : ''}${
                      blockName ? `/${blockName}` : ''
                    }`}</TableCell>
                    <TableCell>{hvRipe}</TableCell>
                    <TableCell>{hvOverripe}</TableCell>
                    <TableCell>{hvUnderripe}</TableCell>
                    <TableCell>{driverName}</TableCell>
                    <TableCell>{vehicleNumber}</TableCell>
                    <TableCell>{renderLinkWithTags(item)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbars>
      </TableContainer>
      <Box sx={{ mt: '16px', display: 'flex', justifyContent: 'flex-end' }}>
        <PaginationCommon
          currentPage={currentPage}
          totalPage={unlinkedHarvestMetadata?.totalPage ?? 1}
          onChangePage={(value) => {
            setCurrentPage(value);
          }}
        />
      </Box>
      {isOpenModalTag && (
        <ConfirmTagModal
          content={t('erms.link_harvest_transaction_with_tag')}
          handleClose={handleCloseConfirmModal}
          handleClickYes={handleClickYes}
        />
      )}
    </CommonContainer>
  );
};

export default UnlinkedHarvestTransactionTable;
