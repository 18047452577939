import { LineString, MultiLineString } from '@turf/turf';
import { AvgStand } from 'assets/images/Analytics/AvgStand';
import { CropsPerRegion } from 'assets/images/Analytics/CropsPerRegion';
import { StandsPerRegion } from 'assets/images/Analytics/StandsPerRegion';
import { TotalTree } from 'assets/images/Analytics/TotalTree';
import { TypeRaster, TYPE_ANALYTICS_MAP_VIEW } from './constants';

/* eslint-disable */
export interface ICreateClientsFormData {
  id?: string;
  _id?: string;
  name: string;
  email: string;
  ssm: string;
  contactNumber?: string;
  contactAddress: string;
  numberOfLevel?: INumberOfLevel[];
  cropType: ICropType[];
  clientType: IClientType;
  avatar?: string;
  avatarFile?: string;
  optionalProperties?: IOptionalProperty[];
  additionalInformation?: IAdditionalInformation[];
  uploadAnalysisConfig?: IUploadAnalysisConfig[];
}

export interface ICreateClientSettings {
  _id?: string;
  clientId?: string;
  unitSetting?: ISettingsClientMeasure[];
  uiSetting?: ISettingsClientUI[];
  sideSurfaceSetting?: ISideSurfaceSetting;
  topSurfaceSetting?: ITopSurfaceSetting;
  favouriteSetting?: ISettingsFavoriteIconUI;
  viewAnalysisPropperties?: ISettingUIAnalysis[];
  sizeOfPointSetting?: ISizeOfPointSetting;
  colorAnalysisLayer?: IColorAnalysisLayer[];
}
export interface ISizeOfPointSetting {
  isFixedPoint?: boolean;
  value?: any;
}

export interface ICropType {
  _id: string;
  name: string;
  icon: string;
  analyticsDisplay: number[];
}

export interface ICreateUserFormData {
  id?: string;
  _id?: string;
  name: string;
  email: string;
  avatar?: string;
  phoneNumber: string;
  role: any;
  zipCode: string;
  state: string;
  country: string;
  jobTitle: string;
  company: string;
  workLocation: string;
  department: string;
  manager: string;
  address: string;
  createdAt: string;
  clientId: any;
  status: boolean;
  joinDate: string | null;
  isSuperAdmin: boolean;
  isSuperUser?: boolean;
  isFirstLogin: boolean;
  tokenExpiredIn?: null | string;
}

export interface ICreateUIFormData {
  clientId: string;
  userId: string;
  flagTab: any[];
}
export interface INewHierarchyStructure {
  parent: string;
  clientId: string;
  cropType: ICropTypes;
  properties: IOptionalProperty[];
  level: number;
  _id: string;
  newParent: string;
  oldParent: string;
}

export interface RenderTree {
  _id: string;
  name: string;
  key: string;
  level: number;
  parent: string | null;
  children?: RenderTree[];
  isLastLevel?: boolean;
  isFavorite?: any;
  refetch?: any;
  recentLevel?: string;
  deleteId?: string | '';
}

export interface IClientData extends ICreateClientsFormData {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUpdateProperties {
  id?: string;
  properties?: IOptionalProperty[];
  personInCharge?: IPersonInChargeInfo[];
  area?: number;
}

export type IOptionalProperty = {
  name: string;
  value: string | string[];
  type: IOptionalPropertyType;
  cropType?: any;
  level?: number;
  isDefault: boolean;
  clientSetupValue?: [];
  key?: string;
  listValue?: string | string[];
  id?: string;
  media?: any;
  mediaId?: string;
};

export type IPersonInChargeInfo = {
  name: string;
  jobTitle: string;
  contactNumber: string;
  email: string;
  avatar: string;
  key?: string;
};

export type IAdditionalInformation = {
  name: string;
  value: string | string[];
  type: IOptionalPropertyType;
  key?: string;
  listValue?: string[];
  media?: any;
};

export interface IUploadAnalysisConfig {
  cropType: string;
  isUpload: boolean;
}

export type IAdditionalInformationEdit = {
  index?: number;
  id?: string;
  name: string;
  value: string | string[];
  type: IOptionalPropertyType;
  key?: string;
  listValue?: string | string[];
};

export type INumberOfLevel = {
  type: ICropTypes;
  value: number;
};

export type ICreateHierarchyStructure = {
  parent: string | null;
  clientId: string;
  cropType: ICropTypes;
  name: string;
};
export type IHierachyStructure = {
  parent: string;
  clientId: string;
  cropType: ICropTypes;
  properties: IOptionalProperty[];
  level: number;
  _id: string;
};
export type IUpdateHierarchyStructureDto = {
  id?: string;
  location?: MapGlDrawSchema;
  properties?: IOptionalProperty[];
  personInCharge?: IPersonInChargeInfo[];
  area?: number;
  fileBoundaryInfo?: IFileBoundaryInfo;
};

export type IFileBoundaryInfo = {
  name?: string;
  size?: number;
};

export type ISettingsDataSensor = {
  id?: string;
  typeOfAnalytic: UserTypeAnalysisArea;
  analysisArea: UserAnalysisArea;
};

export enum UserTypeAnalysisArea {
  RGB_ORTHOIMAGE = 'RGB Orthoimage',
  RGB_ORTHOIMAGE_STAND_COUNT_ANALYSIS = 'RGB Orthoimage Stand count Analysis',
  RGB_ORTHOIMAGE_LAND_USE_ANALYSIS = 'RGB Orthoimage Land Use Analysis',
  RGB_ORTHOIMAGE_CROWN_AREA_ANALYSIS = 'RGB Orthoimage Crown Area Analysis',
  RGB_ORTHOIMAGE_CIRCUMFERENCE_ANALYSIS = 'RGB Orthoimage Circumference Analysis',
  RGB_ORTHOIMAGE_BOUNDING_BOX_ANALYSIS = 'RGB Orthoimage Bounding Box Analysis',
  RGB_ORTHOIMAGE_WATER_LOGGING_ANALYSIS = 'RGB Orthoimage Water Logging Analysis',
  CHLOROPHYLL_ANALYSIS = 'Chlorophyll Analysis',
  YIELD_ANALYSIS = 'Yield Analysis',
  STRESS_ANALYSIS = 'Stress Analysis',
  CROP_CEVERAGE = 'Crop Ceverage',
  VIGER_RASTER_ANALYSIS = 'Viger Raster Analysis',
  VIGER_TREE_CANOPY_RASTER_ANALYSIS = 'Viger Tree Canopy Raster Analysis',
  STRESS_RASTER_ANALYSIS = 'Stress Raster Analysis',
  STRESS_TREE_CANOPY_ANALYSIS = 'Stress Tree Canopy Analysis',
  WATER_UPTAKE_RASTER_ANALYSIS = 'Water Uptake Raster Analysis',
  WATER_UPTAKE_CANOPY_RASTER_ANALYSIS = 'Water Uptake Canopy Raster Analysis',
  CHLOROPHYLL_RASTER_ANALYSIS = 'Chlorophyll Raster Analysis',
  CHLOROPHYLL_TREE_CANOPY_RASTER_ANALYSIS = 'Chlorophyll Tree Canopy Raster Analysis',
}

export enum UserAnalysisArea {
  TREE = 'TREE',
  FIELD = 'FIELD',
}

export type ISettingCropType = {
  id?: string;
  icon: string;
  name: string;
  color: string;
  analyticsDisplay: any;
  analysisConfig: AnalysisConfig[];
  isAutoCreateAnalysisRGB?: any[];
  isAutoCreateAnalysisRgbMs?: any[];
  isAutoCreateAnalysisErms?: any[];
  rgbList?: any[];
  rgbMsList?: any[];
  ermsList?: any[];
  pdfDocument?: string;
};

export type AnalysisConfig = {
  name: string;
  rasterType: string;
  isAutoCreateAnalysis: boolean;
};

export interface ITypeAnalysisOptions {
  id: number;
  label: TYPE_ANALYTICS_MAP_VIEW;
  value: TYPE_ANALYTICS_MAP_VIEW;
  type?: TypeRaster;
}

export enum ICropTypes {
  OIL_PALM = 'OIL PALM',
  RUBBER = 'RUBBER',
  PINEAPPLE = 'PINEAPPLE',
  DURIAN = 'DURIAN',
  PADDY = 'PADDY',
  PAPAYA = 'PAPAYA',
  DRAGON_FRUIT = 'DRAGON FRUIT',
  COCONUT = 'COCONUT',
  TEA = 'TEA',
  COTTON = 'COTTON',
  WHEAT = 'WHEAT',
  EUCALYPTUS = 'EUCALYPTUS',
  APPLE = 'APPLE',
  RAMBUTAN = 'RAMBUTAN',
  HARRY_TYPE = 'HARRY_TYPE',
  BANANA = 'BANANA',
  TERRAIN = 'TERRAIN',
  SUGAR_CANE = 'SUGAR_CANE',
  SAPODILLA = 'SAPODILLA',
  MANGOSTEEN = 'MANGOSTEEN',
  GREENHOUSE = 'GREENHOUSE',
  VEG_ENCROACHMENT = 'VEG ENCROACHMENT',
  HYDROELECTRIC_DAM = 'HYDROELECTRIC DAM',
  FOREST = 'FOREST',
}

export enum IClientType {
  INDEPENDENT = 'INDEPENDENT',
  ENTERPRISE = 'ENTERPRISE',
  ORGANIZATION = 'ORGANIZATION',
}

export enum IOptionalPropertyType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  FILE = 'FILE',
}

export enum IFormType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum IAccessUser {
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  USER = 'USER',
}

export interface ClientFormState {
  setIsUpdateData?: (isUpdateData: boolean) => void;
  clientId?: string;
  formType?: IFormType;
  nextStep?: (step: number) => void;
}

export interface MapGlDrawSchema {
  location?: MapGlDrawLocation;
  type: string;
  features: IFeatureData[];
}

export interface MapGlDrawLocation {
  longitude: number;
  latitude: number;
}

export interface IFeatureData {
  type: string;
  id?: string;
  geometry: IGeometryData[];
  properties?: Object;
}

export interface IGeometryData {
  type: string;
  coordinates: Array<Array<number>> | Array<number>;
}

export type SelectionOptionType = {
  value: string;
  key: string;
};

export type CropArrayOptionsType = {
  value: ICropTypes;
  text: string;
  level: number[];
  icon?: string;
};

export enum ITabPanelType {
  DEFAULT = 'DEFAULT',
  LEVEL = 'LEVEL',
}

export interface InforArray {
  name: string;
  propertyType: IOptionalPropertyType;
  selectOptions: string;
  inputLength: string;
  cropType?: ICropTypes;
  level?: number;
  index?: number;
  id?: string;
}

export interface ICreateClientAdminFormData {
  id?: string;
  clientId: string[];
  avatar?: string;
  name: string;
  email: string;
  phoneNumber: string;
  password?: string;
  passwordConfirm?: string | null;
  role: any;
  status: boolean;
  address?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  isFirstLogin?: boolean;
  isSuperAdmin?: boolean;
  isSuperUser?: boolean;
  zipCode: string;
  state?: string;
  country: string;
  jobTitle: string;
  department: string;
  company: string;
  manager: string;
  workLocation: string;
  viewS3Storage?: string;
}

export interface IMoreInfomation {
  address?: string;
  phoneNumber: string;
  zipCode: string;
  state?: string;
  country: string;
  jobTitle: string;
  department: string;
  company: string;
  manager: string;
  workLocation: string;
}

export enum ClientAdminRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  USER = 'USER',
}

export interface IUserClientCard {
  data: ICreateClientAdminFormData;
  index: number;
  indexUserAdmin: number | null;
  setIndexUserAdmin: (val: number | null) => void;
  setOpenDeleteModal: (val: boolean) => void;
  setUserAdminId: (val: string | null) => void;
  setUserAdminName: (val: string | null) => void;
  isFetching: (val: boolean) => void;
}

export enum TypeSocial {
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
}
export interface ISocialLogin {
  snsToken: string;
  snsType: TypeSocial;
}

export enum TypeModalDelete {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
}

export enum ModalMessageInUserManagement {
  DELETE = 'DELETE',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export interface IManageTable {
  isEdit: boolean;
}

export interface IChangeUserPassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type RoleType = {
  _id?: string;
  id?: string;
  name: string;
  createdAt?: any;
  updatedAt?: any;
  permissions: string[];
  isSubAdmin: boolean;
  isActive: boolean;
  clientId?: any;
  userAssigned?: string[];
};

export const ICropTypesDataView = [
  {
    viewValue: 0,
    viewName: 'Total Trees',
    viewImage: TotalTree,
  },
  {
    viewValue: 1,
    viewName: 'Avg. Stand per Ha',
    viewImage: AvgStand,
  },
  {
    viewValue: 2,
    viewName: 'Crops per Region',
    viewImage: CropsPerRegion,
  },
  {
    viewValue: 3,
    viewName: 'Stands per Region',
    viewImage: StandsPerRegion,
  },
];

export type ISettingsClientMeasure = {
  name: SettingUnitName;
  unit: MeasurementUnit;
  decimalPlace: number;
};

export enum SettingUnitName {
  AREA = 'AREA',
  LENGTH = 'LENGTH',
  OTHER_MEASUREMENTS = 'OTHER_MEASUREMENTS',
}

export enum MeasurementUnit {
  HA = 'ha',
  KM2 = 'km2',
  M2 = 'm2',
  ACRE = 'acre',
  KM = 'km',
  MILES = 'miles',
  M = 'm',
  OTHER = 'other',
}

export type ISettingsClientUI = {
  name: SettingUIName;
  value: number;
};

export type ISettingsFavoriteIconUI = {
  name: string;
  icon: string;
  color: string;
};

export type ISizeConfigSetting = {
  isFixedPoint: boolean;
  value: number | null;
};

export type ISettingUIAnalysis = {
  name: SettingUIAnalysis;
  value: boolean;
};

export interface IColorAnalysisLayer {
  analysisId: string;
  color: string;
  userId: string;
}

export enum SettingUIName {
  STROKE_WIDTH = 'STROKE WIDTH',
  CONTOUR_3D_HEIGHT = '3D CONTOUR HEIGHT',
  CYLINDAR_3D_RADIUS = '3D CYLINDAR RADIUS',
}

export enum SettingUIIconFavorite {
  FAVORITE_ICON = 'FAVORITE ICON',
  FAVORITE_COLOR = 'FAVORITE COLOR',
}

export enum SettingUIAnalysis {
  SHOW_HIDE_SLOPE_ANALYSIS = 'SHOW/HIDE SLOPE ANALYSIS',
}

export enum SettingUISizePointAnalysis {
  SIZE_CONFIG = 'SIZE POINT CONFIG',
}

export enum SettingSectionName {
  UNIT_SETTING = 'UNIT_SETTING',
  UI_SETTING = 'UI_SETTING',
  SIDE_SURFACE_SETTING = 'SIDE_SURFACE_SETTING',
  TOP_SURFACE_SETTING = 'TOP_SURFACE_SETTING',
  FAVORITE_ICON_SETTING = 'FAVORITE_ICON_SETTING',
  VIEW_ANALYSIS_SETTING = 'VIEW_ANALYSIS_SETTING',
  SIZE_POINT_SETTING = 'SIZE_POINT_SETTING',
}

export type ISideSurfaceSetting = {
  category: boolean;
  color: string;
  opacity: number;
};

export type ITopSurfaceSetting = {
  isSameSideSurfaceOpacity: boolean;
  opacity2D: number;
};

export const DEFAULT_BORDER_COLOR = '#1EBADB';
export const CLICKED_STAND_POINT_COLOR = '#FFFF00';

export type IStandCountData = {
  totalTree: number;
  standPerHa: number;
  totalBlankSpot: number;
};

export type IRasterCardItemProps = {
  _id: string;
  id: string;
  parentId?: string;
  status: boolean;
  levelId: string;
  name: string;
  date: string;
  isCompulsoryAnalysis: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  depth: number;
  index: number;
  isLast: boolean;
  parent?: any;
  children?: any;
  relabel?: string;
  label?: string;
};

export interface PlantHealthAnalysisGeometry {
  type: string;
  coordinates: any;
}

export interface IPlantHealthAnalysis {
  _id: string;
  analysisId: string;
  color: string;
  createdAt: string;
  geometry: PlantHealthAnalysisGeometry;
  lduseArea: number;
  ldusePercentage: number;
  sequence: number;
  size: number;
  totalTree: number;
  type: string;
  unit: string;
  updatedAt: string;
  visible?: boolean;
}

export interface S3UsageItem {
  name: string;
  value: number;
}

export enum ViewS3Storage {
  CHART = 'CHART',
  TABLE = 'TABLE',
}

export interface IUpdateViewS3Storage {
  id: string;
  viewS3Storage: string;
}

export interface IMetadata {
  currentPage: number;
  nextPage: number | null;
  pageSize: number;
  pageCount: number;
  totalCount: number;
  totalPage?: number;
}

export interface IStreamlineOrderData {
  colorCode: string;
  geometry: {
    type: string;
    coordinates: any[];
  };
  length: number;
  lengthPercentage: number;
  order: number;
  sensorId?: string;
  workspaceRasterId?: string;
}

export type IStreamlineOrderDisplay = Omit<IStreamlineOrderData, 'geometry'> & { visible: boolean };

export interface ILinesVectorDisplay {
  _id: string;
  colorCode: string;
  lineCategory: string;
  visible: boolean;
  sensorId: string;
  geometry: LineString | MultiLineString;
}

export interface ITreeTiltFallenData {
  geometry: {
    type: string;
    coordinates: any[];
  };
  length: number;
  sensorId: string;
}

export interface ITreeHeightData {
  height: number;
  latY: number;
  longX: number;
  treeId: string;
  _id: string;
}

export interface ICo2SequestrationData extends ITreeHeightData {
  abovegroundCarbonDensity: number;
  basalArea: number;
  co2Sequestration: number;
  woodDensity: number;
}

export interface ITreeHeightDisplay {
  key: number;
  color: string;
  lable: string;
  idList: string[];
  visible: boolean;
  from: number;
  to: number;
}

export interface IRange {
  key: number;
  color: string;
  lable: string;
  visible: boolean;
  from: number;
  to: number;
}

export interface IDataInterval {
  _id: string;
  isDefault: boolean;
  range: IRange[];
}

export interface IPaginationData {
  docs: any[];
  hasNextPage: boolean;
}

export interface IGetUsersQuery {
  page: number;
  keyword: string;
  perPage: number;
  sortDate: string;
  paginationVersion: number;
  clientId: string;
  levelNo?: string;
  levelId?: string;
}
export interface IAddLevelBasedUsers {
  type: string;
  level: number;
  levelId: string;
}
export interface ISelectedUserObject {
  _id: string;
  label: string;
  docs: any;
}

export interface IAnalyticsTabs3DData {
  name: string;
  value: number;
  severity: string;
}

export interface IPolygonFilterCheckBoxState {
  low: boolean;
  medium: boolean;
  high: boolean;
  [key: string]: boolean;
}

export interface IGetTonnageChartQuery {
  clientId: string;
  fromDate: string;
  toDate: string;
  estateId?: string;
  divName?: string;
  yop?: string;
  blockName?: string;
}

export interface IGetCropCalendarRange {
  fromDate: string;
  toDate: string;
  estateId?: string;
  divName?: string;
  yop?: string;
  blockName?: string;
}

export interface IGetCropCalendar {
  date: string;
  estateId?: string;
  divName?: string;
  yop?: string;
  blockName?: string;
}

export interface IGetProductionTrendsChartQuery {
  fromDate: string;
  toDate: string;
  clientId: string;
  estateId?: string;
  divName?: string;
  yop?: string;
  blockName?: string;
  type: string;
}

export interface IGetTripStatusChartQuery {
  fromDate: string;
  toDate: string;
  estateId?: string;
  divName?: string;
  yop?: string;
  blockName?: string;
}

export interface IGetProductionCostChartQuery {
  clientId: string;
  year: string;
  month?: string;
  estateId?: string;
  divName?: string;
  yop?: string;
  blockName?: string;
}

export interface IPolygonFilterSeverity {
  LOW: string;
  MEDIUM: string;
  HIGH: string;
}

export interface IAnalyticsTabs {
  PRODUCTION: string;
  WEIGHBRIDGE: string;
  LOGISTIC: string;
  COST: string;
  EXPENDITURE: string;
  [key: string]: any;
}
export interface ICropHierarchy {
  LOCATION: string;
  DIV_NAME: string;
  YOP: string;
  BLOCK_NAME: string;
}

export interface IProductionTrendsTypes {
  MONTH: string;
  DAY: string;
}

export enum HarvestConfigurationEnum {
  METHOD_A = 'METHOD_A',
  METHOD_B = 'METHOD_B',
}
