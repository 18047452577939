import { Box } from '@mui/material';
import Harvest from 'components/Erms/menu/harvest';
import Overview from 'components/Erms/menu/overview';
import Weighbridge from 'components/Erms/menu/weighbridge';
import { t } from 'i18next';
import { useState } from 'react';
import LeftMenu from './LeftMenu';

export interface IListMenuItem {
  key: number;
  value: string;
  label: string;
}

const ErmsContainer = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(1);

  const listMenuItem: IListMenuItem[] = [
    { key: 1, value: 'Overview', label: t('erms.overview') },
    { key: 2, value: 'Harvest', label: t('erms.harvest') },
    { key: 3, value: 'Weighbridge', label: t('erms.weighbridge') },
    { key: 4, value: 'Upkeep', label: t('erms.upkeep') },
    { key: 5, value: 'Operation_Group', label: t('erms.operation_group') },
    { key: 6, value: 'Human_Resource', label: t('erms.human_resource') },
  ];

  const renderContent = () => {
    switch (selectedIndex) {
      case 1:
        return <Overview />;
      case 2:
        return <Harvest />;
      case 3:
      default:
        return <Weighbridge />;
    }
  };

  return (
    <Box display="flex" flexDirection="row">
      <LeftMenu listMenuItem={listMenuItem} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
      {renderContent()}
    </Box>
  );
};

export default ErmsContainer;
