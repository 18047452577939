import { Grid } from '@mui/material';
import { ButtonCropType } from 'components/Common/ButtonCropType';
import { QUERY_KEY } from 'constants/constants';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getClientById } from 'services/clients/apiClient.services';
import { getAllSettingsDataCroptype } from 'services/settings/settings.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeCropType, changeLevelId, mapViewSelector } from 'store/slices/mapViewSlice';

const SelectCropType = () => {
  const { clientId } = useParams();
  const { cropType } = useAppSelector(mapViewSelector);
  const dispatch = useAppDispatch();

  const { data: cropTypeData } = useQuery([QUERY_KEY.USER_SETTINGS_CROPTYPE], () => getAllSettingsDataCroptype());

  const { data: clientData } = useQuery([QUERY_KEY.CLIENT_DATA, clientId], () => getClientById(clientId || ''), {
    enabled: !!clientId,
  });

  const dataSettingsAnalyticsValue = useMemo(() => {
    return cropTypeData?.data || [];
  }, [cropTypeData]);

  const cropTypeOptions = useMemo(() => {
    return clientData?.cropType || [];
  }, [clientData]);

  const filteredData = useMemo(() => {
    return dataSettingsAnalyticsValue.filter((item: any) => cropTypeOptions.some((val: any) => val._id === item._id));
  }, [dataSettingsAnalyticsValue, cropTypeOptions]);

  const setCropTypeSelected = useCallback(
    (type?: string) => {
      dispatch(changeCropType(type));
    },
    [dispatch]
  );

  const setLevelId = useCallback(() => {
    dispatch(changeLevelId({ levelId: undefined, isLevelLasted: undefined }));
  }, [dispatch]);

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 2, sm: 2, md: 2 }}
      sx={{ gap: '4px', p: '16px', backgroundColor: (theme) => theme.palette.background.paper }}>
      {filteredData
        .sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
        .map((item: any, index: number) => (
          <Grid item display="flex" gap="10px" key={index}>
            <ButtonCropType
              isSelected={item._id === (cropType || '')}
              label={item.name}
              icon={item.icon}
              selectedCropType={() => {
                if (cropType !== item._id) {
                  setCropTypeSelected(item._id);
                } else {
                  setCropTypeSelected();
                }
                setLevelId();
              }}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default SelectCropType;
