import { Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { IWeighbridgeTransactionTableResponse } from 'interfaces/erms';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CommonContainer from '../components/CommonContainer';

interface WeighbridgeTransactionTableProps {
  allWeighbridgeTransaction: IWeighbridgeTransactionTableResponse[];
}

const WeighbridgeLocation: FC<WeighbridgeTransactionTableProps> = ({ allWeighbridgeTransaction }) => {
  const { t } = useTranslation();
  const chartData = React.useMemo(() => {
    const locationCounts: Record<string, number> = {};

    allWeighbridgeTransaction.forEach((transaction) => {
      const location = transaction.weighbridgeLocation;
      if (location) {
        locationCounts[location] = (locationCounts[location] || 0) + 1;
      }
    });

    return Object.entries(locationCounts).map(([name, value]) => ({ name, value }));
  }, [allWeighbridgeTransaction]);

  const chartOptions = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: t('erms.weighbridge'),
        type: 'pie',
        radius: '70%',
        center: ['50%', '40%'],
        data: chartData,
        label: {
          position: 'inside',
          formatter: '{b}',
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        itemStyle: {
          borderWidth: 0,
        },
      },
    ],
  };

  return (
    <CommonContainer style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '320px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, textAlign: 'center' }}>
        {t('erms.weighbridge_location')}
      </Typography>
      <ReactECharts option={chartOptions} style={{ height: 300, width: '100%', margin: 0, padding: 0 }} />
    </CommonContainer>
  );
};

export default WeighbridgeLocation;
