import { Box, Button, useTheme } from '@mui/material';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import CommonModal from 'components/Common/CommonModal';
import { addYears, endOfDay, endOfYear, isSameDay, startOfYear } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import { IDateRange } from '../../interfaces';
import { calendarStyle } from '../../styles/calendar';

interface CalendarModalProps {
  handleClose: () => void;
  isShow: boolean;
  setSelectedDate: (arg: IDateRange) => void;
  selectedDate: IDateRange;
  dateOldestIssue?: Date;
}

const CalendarModal: FC<CalendarModalProps> = ({
  isShow,
  selectedDate,
  handleClose,
  setSelectedDate,
  dateOldestIssue,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = calendarStyle();
  const [dateSelect, setDateSelect] = useState<IDateRange[]>([selectedDate]);
  const handlerCloseModal = () => {
    setDateSelect([selectedDate]);
    handleClose();
  };

  useEffect(() => {
    setDateSelect([selectedDate]);
  }, [selectedDate]);

  const getStaticRages = () => {
    const ranges = [
      ...defaultStaticRanges.map((range) => ({
        ...range,
        label: t(convertTextToMultiLanguage(range.label!, 'trans')),
      })),
      {
        label: t('trans.this_year'),
        range: () => ({
          startDate: startOfYear(new Date()),
          endDate: endOfDay(new Date()),
        }),
        isSelected(range: any) {
          const definedRange: any = this.range();
          return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
      },
      {
        label: t('trans.last_year'),
        range: () => ({
          startDate: startOfYear(addYears(new Date(), -1)),
          endDate: endOfYear(addYears(new Date(), -1)),
        }),
        isSelected(range: any) {
          const definedRange: any = this.range();
          return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
      },
    ];
    if (dateOldestIssue) {
      ranges.push({
        label: t('trans.reset_to_default'),
        range: () => ({
          startDate: dateOldestIssue,
          endDate: endOfDay(new Date()),
        }),
        isSelected(range: any) {
          const definedRange: any = this.range();
          return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
        },
      });
    }
    return ranges;
  };

  return (
    <CommonModal isShow={isShow} handleClose={handleClose}>
      <Box className={styles.modal}>
        <DateRangePicker
          onChange={(item) => setDateSelect([item.selection] as any)}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateSelect}
          direction="horizontal"
          rangeColors={[theme.palette.primary.main, theme.palette.primary.contrastText]}
          color={theme.palette.primary.main}
          staticRanges={getStaticRages()}
        />
        <Box width={'225px'} display={'flex'} style={{ marginLeft: 'auto' }}>
          <Button
            style={{ marginRight: '10px', marginLeft: 'auto', color: '#fff', backgroundColor: '#4fe3ae' }}
            onClick={() => {
              setSelectedDate(dateSelect[0]);
              handleClose();
            }}
            variant="contained">
            {t('trans.apply')}
          </Button>
          <Button style={{ marginRight: '10px' }} color="error" onClick={handlerCloseModal} variant="contained">
            {t('trans.cancel')}
          </Button>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default CalendarModal;
