import { Box, Button, Typography } from '@mui/material';
import CommonModal from 'components/Common/CommonModal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmTagModalProps {
  content: string;
  handleClose: () => void;
  handleClickYes: () => void;
}

const ConfirmTagModal: FC<ConfirmTagModalProps> = ({ handleClose, handleClickYes, content }) => {
  const { t } = useTranslation();

  return (
    <CommonModal
      isShow={true}
      handleClose={handleClose}
      closeIconColor="none"
      style={{ height: '140px', width: '360px' }}>
      <Typography sx={{ whiteSpace: 'pre-line', padding: '30px', textAlign: 'center' }}>{content}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0px 70px' }}>
        <Button
          onClick={handleClickYes}
          style={{ background: '#4FE3AE', color: 'white', height: '30px', width: '86px' }}>
          {t('trans.yes')}
        </Button>
        <Button
          onClick={handleClose}
          style={{ marginLeft: '10px', background: '#FF0506', color: 'white', height: '30px', width: '86px' }}>
          {t('trans.cancel')}
        </Button>
      </Box>
    </CommonModal>
  );
};

export default ConfirmTagModal;
