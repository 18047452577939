import { FILE_TYPE, LayerTypeEnum, mapFileToApi } from 'common/defines/constants';
import { SortDateEnum } from 'components/MapView/RightBar/IssuesTab/interfaces';
import { ICreateHarvestLinkRequest } from 'interfaces/erms';
import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostRequest } from 'services';
import { ILinesVectorDisplay, IStreamlineOrderData } from './../../common/defines/clients';

export const getRasterListByPagination = async (
  page: number,
  perPage: number,
  keyword: string | null,
  analysisArea: string | null = '',
  levelId: string | null
) => {
  return sendGetRequest(
    `/data-analytics/pagination?keyword=${keyword}&page=${page}&perPage=${perPage}&sortDate=desc&paginationVersion=2&analysisArea=${analysisArea}&levelId=${levelId}`
  );
};

export const postCreateAnalytics = (data: any) => {
  return sendPostRequest('/data-analytics', data);
};

export const getAllRaster = (levelId: string | null = '') =>
  sendGetRequest(`/data-analytics/getDataAnalysis/${levelId}`);

export const updateRasterAnalytic = (data: { id: string; date: string; description: string }) =>
  sendPatchRequest(`/data-analytics/${data.id}`, {
    date: data.date,
    description: data.description,
  });

export const updateAnalyticRelabel = (data: { id: string; relabel: string }) =>
  sendPatchRequest(`/data-analytics/updateDataAnalysisRelabel/${data.id}`, {
    relabel: data.relabel,
  });

export const deleteRasterAnalytic = (id: string) => {
  return sendDeleteRequest(`/data-analytics/${id}`);
};

export const deleteFileJsonphotointelligenceAnalytic = (analysisId: string) => {
  return sendDeleteRequest(`${analysisId}`); //todo
};

export const createLandUse = (params: any) => sendPostRequest(`/land-use-analysis`, params);

export const getListFavouritesLocation = (clientId: string, cropType: string) => {
  return sendGetRequest(`/level-favorite/${cropType}/${clientId}`);
};

export const setFavoriteAnalytic = (params: any) => sendPostRequest(`/level-favorite`, params);
export const getFavoriteAnalytic = (clientId: string) => sendGetRequest(`/level-favorite/${clientId}`);
export const getListLevelIdFavorite = (clientId: string) =>
  sendGetRequest(`/level-favorite/getListLevelIdFavorite/${clientId}`);

export const createCropCover = (params: any) => sendPostRequest(`/crop-cover`, params);

export const createStandCount = (params: any) => sendPostRequest(`/stand-count-analysis`, params);

export const createCircumference = (params: any) => sendPostRequest(`/circumference-analysis`, params);

export const createTreeHeight = (params: any) => sendPostRequest(`/tree-height-analysis`, params);

export const createCo2Sequestration = (params: any) => sendPostRequest(`/co2-sequestration-analysis`, params);

export const createTreeTiltFallen = (params: any) => sendPostRequest(`/tree-tilt-fallen/importTreeTiltFallen`, params);

export const createInterval = (params: any) => sendPostRequest(`/interval-limit-analysis/createIntervalLimit`, params);

export const updateInterval = (id: string, params: any) =>
  sendPatchRequest(`/interval-limit-analysis/intervalLimit/${id}`, params);

export const getDataLandUseAnalytic = (analysisId: string, date: string) =>
  sendGetRequest(`/land-use-analysis/getData/${analysisId}?date=${date}`);

export const getDataCircumfernenceAnalytic = (analysisId: string) =>
  sendGetRequest(`/circumference-analysis/getAll/${analysisId}`);

export const getDataTreeHeightAnalytic = (analysisId: string) => sendGetRequest(`/tree-height-analysis/${analysisId}`);

export const getDataTreeHeightAnalyticPagination = (data: { analysisId: string; page: number }) => {
  const { analysisId, page } = data;
  const sortDate = 'desc';
  const perPage = 1000;
  const paginationVersion = 2;
  return sendGetRequest(`/tree-height-analysis/pagination`, { analysisId, page, sortDate, perPage, paginationVersion });
};

export const getDataCo2SequestrationAnalytic = (analysisId: string) =>
  sendGetRequest(`/co2-sequestration-analysis/${analysisId}`);

export const getDataCo2SequestrationAnalyticPagination = (data: { analysisId: string; page: number }) => {
  const { analysisId, page } = data;
  const sortDate = 'desc';
  const perPage = 1000;
  const paginationVersion = 2;
  return sendGetRequest(`/co2-sequestration-analysis/pagination`, {
    analysisId,
    page,
    sortDate,
    perPage,
    paginationVersion,
  });
};

export const getDataPlantHealthOilPailmAnalytic = (analysisId: string) =>
  sendGetRequest(`/plant-health-oilpalm-analysis/getAll/${analysisId}`);

export const getDataIntervals = (analysisId: string) =>
  sendGetRequest(`/interval-limit-analysis/intervalLimit/${analysisId}`);

export const getRasterDetails = (id: string) => sendGetRequest(`/data-analytics/${id}`);

export const postUploadZipFileAnalytics = (params: any) => sendPostRequest(`/data-analytics/importZipFile`, params);

export const uploadVectorContourData = (params: FormData) =>
  sendPostRequest(`data-analytics/importVectorContourData`, params);

export const uploadRasterImageData = (request: {
  analysisId: string;
  name: string;
  size: number;
  path: string;
  layerType: LayerTypeEnum;
}) => {
  const { analysisId, name, size, path, layerType } = request;
  return sendPostRequest(`/co2-sequestration-analysis/rasterLegend`, { analysisId, name, size, path, layerType });
};

export const uploadStreamLineOrderData = (formData: FormData) =>
  sendPostRequest(`data-analytics/importStreamLineOrder`, formData);

export const uploadTreeTiltFallenData = (formData: FormData) =>
  sendPostRequest(`data-analytics/importTreeTiltFallen`, formData);

export const uploadLineStringData = (formData: FormData) => sendPostRequest(`data-analytics/importLines`, formData);

export const getDownloadZipFileAnalytics = (analysisId: string, fileType: FILE_TYPE) => {
  if (fileType === FILE_TYPE.TREE_TILT_FALLEN) {
    return sendGetRequest(`/tree-tilt-fallen/downloadTreeTiltFallen/${analysisId}`);
  }
  let dataType = '';
  if (fileType === FILE_TYPE.POINT_FILE) dataType = '/POINT';
  else if (fileType === FILE_TYPE.LINE_STRING_FILE) dataType = '/LINE';
  else if (fileType === FILE_TYPE.POLYGON_FILE) dataType = '/POLYGON';

  let apiContent = '';
  if (fileType === FILE_TYPE.PLANTATION_BOUNDARY_GEOJSON) apiContent = 'downloadPlantationData';
  else if (fileType === FILE_TYPE.STAND_COUNT_CROP_BOUNDARY) apiContent = 'downloadBoundary';
  else apiContent = 'downloadFileData';

  return sendGetRequest(`/${mapFileToApi[fileType]}/${apiContent}/${analysisId}${dataType}`);
};

export const getExportAnalyticScript = (analysisId: string | null) => {
  return sendGetRequest(`data-analytics/exportAnalyticScript/${analysisId}`);
};

export const getDownloadFileRasterLayer = (layerId: string) => {
  return sendGetRequest(`/data-analytics/downloadRasterLayer/${layerId}`);
};

export const getDownloadStreamlineOrder = (analysisId: string) => {
  return sendGetRequest(`/stream-line-order/downloadStreamLineOrder/${analysisId}`);
};

export const getDownloadLines = (analysisId: string) => {
  return sendGetRequest(`/lines/downloadLinesVector/${analysisId}`);
};

export const getDownloadTreeTiltFallen = (analysisId: string) => {
  return sendGetRequest(`/tree-tilt-fallen/downloadTreeTiltFallen/${analysisId}`);
};

export const getRasterLayer = (levelId: string, date: string) => {
  return sendGetRequest(`/data-analytics/getListLayer/${levelId}/${date}`);
};

export const getRasterLegend = (analysisId: string) => {
  return sendGetRequest(`/co2-sequestration-analysis/rasterLegend/${analysisId}`);
};

export const getSizeVector = (sensorId: string) => {
  return sendGetRequest(`/vector-contour/getSize/${sensorId}`);
};

export const getVectorContour = (data: { page: number; sensorId: string; size: string }) => {
  const { page, sensorId, size } = data;
  return sendGetRequest(`/vector-contour/pagination?page=${page}&perPage=${500}&sensorId=${sensorId}&size=${size}`);
};

export const getStreamlineOrderList = (data: {
  page: number;
  perPage: number;
  sortDate: SortDateEnum;
  sensorId: string;
}) => {
  return sendGetRequest(`/stream-line-order/pagination`, data);
};

export const getLinesVectorBySensorId = (sensorId: string) => {
  return sendGetRequest(`/lines/getAllLinesBySensorId/${sensorId}`);
};

export const getAllLines = (sensorId: string) => {
  const page = 1;
  const perPage = 500;
  const sortDate = 'asc';
  const paginationVersion = 2;
  return sendGetRequest(`/lines/pagination`, { page, perPage, sortDate, paginationVersion, sensorId });
};

export const getTreeTiltFallenList = (data: { page: number; sensorId: string }) => {
  return sendGetRequest(`/tree-tilt-fallen/pagination`, {
    ...data,
    perPage: 500,
    sortDate: SortDateEnum.DESC,
    paginationVersion: 2,
  });
};

export const updateStreamlineOrderColor = (data: Pick<IStreamlineOrderData, 'sensorId' | 'order' | 'colorCode'>) => {
  const { sensorId, order, colorCode } = data;
  return sendPatchRequest(`/stream-line-order/updateStreamLineOrderColor/${sensorId}`, { order, colorCode });
};

export const updateLinesVectorColor = (data: Pick<ILinesVectorDisplay, '_id' | 'colorCode'>) => {
  const { _id, colorCode } = data;
  return sendPatchRequest(`/lines/updateLinesColor/${_id}`, { colorCode });
};

export const getDataCropCoverAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/crop-cover/${analysisId}/${size}`);

export const getDataPlantHealthAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/plant-health-analysis/getAll/${analysisId}/${size}`);

export const getDataTilerDensityAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/tiller-density-analysis/getAll/${analysisId}/${size}`);

export const getVacantArea = (rasterId: string) => sendGetRequest(`/data-analytics/getVacantArea/${rasterId}`);

export const getVacantSpot = (analysisId: string, size: number) =>
  sendGetRequest(`/vacant-area-analysis/getVacantSpot/${analysisId}/${size}`);

export const getDataVacantAreaAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/vacant-area-analysis/getAll/${analysisId}/${size}`);

export const getChlorophyllPaddyAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/chlorophyll-paddy-analysis/getAll/${analysisId}/${size}`);

export const getVigorPaddyAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/vigor-paddy-analysis/getAll/${analysisId}/${size}`);

export const getStressFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/stress-field-analysis/getAll/${analysisId}/${size}`);

export const getWaterUptakeFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/water-uptake-field-analysis/getAll/${analysisId}/${size}`);

export const getSoilWaterContentFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/soil-water-content-field-analysis/getAll/${analysisId}/${size}`);

export const getSoilErosionFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/soil-erosion/getAll/${analysisId}/${size}`);

export const getLeafPhenologyFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/leaf-phenology-field-analysis/getAll/${analysisId}/${size}`);

export const getElevationFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/elevation-field-analysis/getAll/${analysisId}/${size}`);

export const getSlopeFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/slope-field-analysis/getAll/${analysisId}/${size}`);

export const getVegetationEncroachmentAnalytic = (analysisId: string, size: number, bufferSize: number) =>
  sendGetRequest(`/vegetation-encroachment-analysis/getAllBySizeAndBuffer`, {
    analysisId,
    size,
    bufferSize,
  });

export const getCustomFieldAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/custom-field-analysis/getAll/${analysisId}/${size}`);

export const getSizeCropCoverAnalytic = (analysisId: string) => sendGetRequest(`/crop-cover/getSize/${analysisId}`);

export const getPointDataLandUseAnalytic = (analysisId: string) =>
  sendGetRequest(`/land-use-analysis/getPoint/${analysisId}`);

export const getStandCountAnalytic = (analysisId: string) =>
  sendGetRequest(`/stand-count-analysis/getAll/${analysisId}`);

export const checkIsExistPoint = (analysisId: string) =>
  sendGetRequest(`/land-use-analysis/pointIsExist/${analysisId}`);

export const getEnableSlopeArea = (analysisId: string) =>
  sendGetRequest(`/land-use-analysis/enableSlopeArea/${analysisId}`);

export const getStandCountChartAnalytic = (analysisId: string) =>
  sendGetRequest(`/stand-count-analysis/standCountChartData/${analysisId}`);

export const getPlantationCropCoverage = (analyticId: string) => sendGetRequest(`/crop-cover/plantation/${analyticId}`);

export const getCheckExistPlantationCropCoverage = (analyticId: string) =>
  sendGetRequest(`/crop-cover/plantationIsExist/${analyticId}`);

export const createVigor = (params: any) => sendPostRequest(`/vigor-analysis`, params);

export const createPhotoIntelligence = (params: any) => sendPostRequest(`/photo-intelligence-analysis`, params);

export const removePhotoIntelligenceGeojsonFile = (levelId: string) => sendDeleteRequest(`/${levelId}`);

export const getDataVigorAnalytic = (analysisId: string) => sendGetRequest(`/vigor-analysis/${analysisId}`);

export const postLevelRecent = (levelId: string) => sendPostRequest(`/level-recent`, { levelId });

export const getAllLevelRecent = (clientId: string, croptype: string) =>
  sendGetRequest(`/level-recent/getListLevelRecent/${croptype}/${clientId}`);

export const getListRecentRecords = (clientId: string) =>
  sendGetRequest(`/level-recent/getListRecentRecords/${clientId}`);

export const removeLevelRecent = (levelId: string) => sendDeleteRequest(`/level-recent/${levelId}`);

export const createStressAnalytic = (params: any) => sendPostRequest(`/stress-analysis`, params);

export const getDataPlantStressAnalytic = (analysisId: string) => sendGetRequest(`/stress-analysis/${analysisId}`);

export const createCrownAreaAnalytic = (params: any) => sendPostRequest(`/crown-area-analysis`, params);

export const getDataCrownAreaAnalytic = (analysisId: string) => sendGetRequest(`/crown-area-analysis/${analysisId}`);

export const createGapAnalytic = (params: any) => sendPostRequest(`/gap-analysis`, params);

export const getDataGapAnalytic = (analysisId: string) => sendGetRequest(`/gap-analysis/${analysisId}`);

export const createWaterUptakeAnalytic = (params: any) => sendPostRequest(`/water-uptake-analysis`, params);

export const createPlantHealthOilPalmAnalytic = (params: any) =>
  sendPostRequest(`/plant-health-oilpalm-analysis`, params);

export const createHarvestAnalytic = (params: any) => sendPostRequest('/harvest-analysis', params);

export const createHarvestLinkTag = (data: ICreateHarvestLinkRequest) =>
  sendPostRequest(`/harvest-analysis/createLink`, data);

export const createWeighbridgeAnalytic = (params: any) => sendPostRequest('/weighbridge-analysis', params);

export const getDataWaterUptakeAnalytic = (analysisId: string) =>
  sendGetRequest(`/water-uptake-analysis/${analysisId}`);

export const createChlorophyllAnalytic = (params: any) => sendPostRequest(`/chlorophyll-analysis`, params);

export const getDataChlorophyllAnalytic = (analysisId: string) =>
  sendGetRequest(`/chlorophyll-analysis/getAll/${analysisId}`);

export const getDataTreeTiltFallenAnalytic = (analysisId: string) =>
  sendGetRequest(`/tree-tilt-fallen/getAllByAnalysisId/${analysisId}`);

// weed invasion
export const createWeedInvasionAnalytic = (params: any) => sendPostRequest(`/weed-invasion-analysis`, params);

export const getAllDataWeedInvasionAnalytic = (analysisId: string, size: string) =>
  sendGetRequest(`/weed-invasion-analysis/getAll/${analysisId}/${size}`);

export const getSizeWeedInvasionAnalytic = (analysisId: string) =>
  sendGetRequest(`/weed-invasion-analysis/getSize/${analysisId}`);

// vacant area
export const createVacantAreaAnalytic = (params: any) => sendPostRequest(`/vacant-area-analysis`, params);

export const createTillerDensityAnalytic = (params: any) => sendPostRequest(`/tiller-density-analysis`, params);

export const createPlantHealthAnalytic = (params: any) => sendPostRequest(`/plant-health-analysis`, params);

export const createChlorophyllPaddyAnalytic = (params: any) => sendPostRequest(`/chlorophyll-paddy-analysis`, params);

export const createVigorPaddyAnalytic = (params: any) => sendPostRequest(`/vigor-paddy-analysis`, params);

export const createStressFieldAnalytic = (params: any) => sendPostRequest(`/stress-field-analysis`, params);

export const createWaterUptakeFieldAnalytic = (params: any) => sendPostRequest(`/water-uptake-field-analysis`, params);

export const createSoilWaterContentFieldAnalytic = (params: any) =>
  sendPostRequest(`/soil-water-content-field-analysis`, params);

export const createSoilErosionFieldAnalytic = (params: any) => sendPostRequest(`/soil-erosion`, params);
export const createLeafPhenologyFieldAnalytic = (params: any) =>
  sendPostRequest(`/leaf-phenology-field-analysis`, params);

export const createElevationFieldAnalytic = (params: any) => sendPostRequest(`/elevation-field-analysis`, params);

export const createSlopeFieldAnalytic = (params: any) => sendPostRequest(`/slope-field-analysis`, params);

export const createVegetationEncroachmentAnalytic = (params: any) =>
  sendPostRequest(`/vegetation-encroachment-analysis`, params);

export const createCustomFieldAnalytic = (params: any) => sendPostRequest(`/custom-field-analysis`, params);

export const createSoilMoistureContentAnalytic = (params: any) => sendPostRequest(`/soil-moisture-content`, params);

export const createFissureAnalytic = (params: any) => sendPostRequest(`/fissure`, params);

export const createWatershedBasin = (params: any) => sendPostRequest(`/watershed-basin`, params);

export const getDataWatershedBasinAnalytic = (analysisId: string) =>
  sendGetRequest(`/watershed-basin/getAll/${analysisId}`);

export const getSizePlantHealthAnalytic = (analysisId: string) =>
  sendGetRequest(`/plant-health-analysis/getSize/${analysisId}`);

export const getSizeTilerDensityAnalytic = (analysisId: string) =>
  sendGetRequest(`/tiller-density-analysis/getSize/${analysisId}`);

export const getSizeVacantAreaAnalytic = (analysisId: string) =>
  sendGetRequest(`/vacant-area-analysis/getSize/${analysisId}`);

export const getSizeChlorophyllPaddyAnalytic = (analysisId: string) =>
  sendGetRequest(`/chlorophyll-paddy-analysis/getSize/${analysisId}`);

export const getSizeVigorPaddyAnalytic = (analysisId: string) =>
  sendGetRequest(`/vigor-paddy-analysis/getSize/${analysisId}`);

export const getSizeStressFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/stress-field-analysis/getSize/${analysisId}`);

export const getSizeWaterUptakeFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/water-uptake-field-analysis/getSize/${analysisId}`);

export const getSizeSoilWaterContentFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/soil-water-content-field-analysis/getSize/${analysisId}`);

export const getSizeSoilErosionFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/soil-erosion/getSize/${analysisId}`);

export const getSizeLeafPhenologyFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/leaf-phenology-field-analysis/getSize/${analysisId}`);

export const getSizeElevationFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/elevation-field-analysis/getSize/${analysisId}`);

export const getSizeSlopeFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/slope-field-analysis/getSize/${analysisId}`);

export const getSizeVegetationEncroachmentAnalytic = (analysisId: string) =>
  sendGetRequest(`/vegetation-encroachment-analysis/getSize/${analysisId}`);

export const getBufferSizeVegetationEncroachmentAnalytic = (analysisId: string) =>
  sendGetRequest(`/vegetation-encroachment-analysis/getBufferSize/${analysisId}`);

export const getSizeCustomFieldAnalytic = (analysisId: string) =>
  sendGetRequest(`/custom-field-analysis/getSize/${analysisId}`);

export const updateColorForAnalytic = (key: string, data: Array<{ id: string; color: string }>) =>
  sendPatchRequest(`/${key}`, data);

export const updateColorForVegetationEncroachment = (data: { analysisId: string; type: string; color: string }) => {
  return sendPatchRequest(`/vegetation-encroachment-analysis`, data);
};

export const getTreeIdTemplate = (analyticId: string | null) =>
  sendGetRequest(`/stand-count-analysis/getTreeIdTemplate/${analyticId}`);

export const getAnalyticBoxPlot = (analyticId: string, type: string) => {
  let URL = `${type}-analysis`;
  if (type === 'TreeTiltFallen') {
    URL = 'tree-tilt-fallen';
  }
  return sendGetRequest(`/${URL}/getBoxPlot/${analyticId}`);
};

export const getAnalyticPolygon = (analyticId: string, type: string, page: number, pageSize?: number) => {
  const perPage = pageSize ?? 500;
  return sendGetRequest(
    `/${type}-analysis/getPolygon?page=${page}&perPage=${perPage}&paginationVersion=2&analysisId=${analyticId}`
  );
};

export const updateEditedCircumference = (data: {
  cirLength: number;
  diaLength: number;
  geometry: GeoJSON.Geometry;
  id: string;
}) => sendPatchRequest(`/circumference-analysis`, data);

export const updateCrownArea = (data: { area: number; id: string; geometry: GeoJSON.Geometry }) =>
  sendPatchRequest(`/crown-area-analysis`, data);

export const deleteVirgoTreeGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/vigor-analysis/deleteAnalysisData/${analysisId}`);

export const deleteVirgoFieldGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/vigor-paddy-analysis/deleteAnalysisData/${analysisId}`);

export const deleteStressTreeGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/stress-analysis/deleteAnalysisData/${analysisId}`);

export const deleteStressFieldGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/stress-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteWaterUptakeTreeGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/water-uptake-analysis/deleteAnalysisData/${analysisId}`);

export const deleteWaterUptakeFieldGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/water-uptake-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteChlorophyllTreeGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/chlorophyll-analysis/deleteAnalysisData/${analysisId}`);

export const deleteChlorophyllFieldGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/chlorophyll-paddy-analysis/deleteAnalysisData/${analysisId}`);

export const deletePlantHealthAnalysisGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/plant-health-analysis/deleteAnalysisData/${analysisId}`);

export const deletePlantHealthOilPalmGeojsonFile = (analysisId: string) =>
  sendDeleteRequest(`/plant-health-oilpalm-analysis/deleteAnalysisData/${analysisId}`);

export const deleteIntervalAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/interval-limit-analysis/deleteIntervalLimitData/${analysisId}`);

export const deleteWeekInvasionAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/weed-invasion-analysis/deleteAnalysisData/${analysisId}`);

export const deleteTillerDensityAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/tiller-density-analysis/deleteAnalysisData/${analysisId}`);

export const deleteVacantAreaAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/vacant-area-analysis/deleteAnalysisData/${analysisId}`);

export const deleteCropCoverAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/crop-cover/deleteAnalysisData/${analysisId}`);

export const deleteCropPlantationData = (analysisId: string) =>
  sendDeleteRequest(`/crop-cover/deleteCropPlantation/${analysisId}`);

export const deleteCrownAreaAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/crown-area-analysis/deleteAnalysisData/${analysisId}`);

export const deleteGapAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/gap-analysis/deleteAnalysisData/${analysisId}`);

export const deleteTreeTiltFallenAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/tree-tilt-fallen/deleteAnalysisData/${analysisId}`);

export const deleteStandCountAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/stand-count-analysis/deleteAnalysisData/${analysisId}`);

export const deleteStandCountBoundaryData = (analysisId: string) =>
  sendDeleteRequest(`/stand-count-analysis/deleteBoundary/${analysisId}`);

export const deleteCircumferenceAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/circumference-analysis/deleteAnalysisData/${analysisId}`);

export const deleteTreeHeightAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/tree-height-analysis/deleteAnalysisData/${analysisId}`);

export const deleteCo2SequestrationAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/co2-sequestration-analysis/deleteAnalysisData/${analysisId}`);

export const deleteLandUseAnalysisData = (analysisId: string, fileType?: string) => {
  let dataType = '';
  if (fileType === FILE_TYPE.POINT_FILE) dataType = 'POINT';
  else if (fileType === FILE_TYPE.LINE_STRING_FILE) dataType = 'LINE';
  else if (fileType === FILE_TYPE.POLYGON_FILE) dataType = 'POLYGON';
  return sendDeleteRequest(`/land-use-analysis/deleteAnalysisData/${analysisId}/${dataType}`);
};

export const deleteSoilWaterContentFieldAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/soil-water-content-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteSoilErosionFieldAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/soil-erosion/deleteAnalysisData/${analysisId}`);

export const deleteLeafPhenologyFieldAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/leaf-phenology-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteElevationFieldAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/elevation-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteSlopeFieldAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/slope-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteVegetationEncroachmentAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/vegetation-encroachment-analysis/deleteAnalysisData/${analysisId}`);

export const deleteCustomFieldAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/custom-field-analysis/deleteAnalysisData/${analysisId}`);

export const deleteSoilMoistureContentAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/soil-moisture-content/deleteAnalysisData/${analysisId}`);

export const deleteFissureAnalysisData = (analysisId: string) =>
  sendDeleteRequest(`/fissure/deleteAnalysisData/${analysisId}`);

export const deleteWatershedBasinData = (analysisId: string) =>
  sendDeleteRequest(`/watershed-basin/deleteAnalysisData/${analysisId}`);

// hypothetical api for delete raster
export const deleteRasterAnalysisData = (analysisId: string, layerType?: string) =>
  sendDeleteRequest(`/data-analytics/layer/${analysisId}/${layerType}`);
