import AppLayout from 'components/AppLayout';
import WaitingPageAPI from 'components/Common/WaitingAPI';
import { QUERY_KEY } from 'constants/constants';
import useQueryMe from 'hooks/common/useQueryMe';
import { ReactElement } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUIDisplay } from 'services/clients/apiClient.services';
import ErmsContainer from './erms-container';

export const ErmsPage = (): ReactElement => {
  const { clientId } = useParams();
  const { userId, isSuperAdmin, isSuperUser, loadingMe } = useQueryMe();

  const { data: userInfoByClient, isLoading: loadingUI } = useQuery(
    [QUERY_KEY.USER_INFO_BY_CLIENT, clientId, userId],
    () => getUIDisplay(clientId!, userId),
    {
      enabled: !!clientId && !!userId && !isSuperAdmin && !isSuperUser,
    }
  );

  return (
    <>
      {loadingMe || loadingUI ? (
        <WaitingPageAPI height="100vh" width="100vw" />
      ) : (
        <AppLayout style={{ minHeight: undefined, height: '100vh' }}>
          <ErmsContainer />
        </AppLayout>
      )}
    </>
  );
};
