import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { ITreeNodeCheckbox } from 'interfaces/erms';
import { ChangeEvent, FC, ReactNode } from 'react';
import CustomContent from './CustomContent';
import StyledTreeItemRoot from './StyledTreeItemRoot';

interface StyledTreeItemProps {
  itemData: ITreeNodeCheckbox;
  setTreeData: (update: (prev: ITreeNodeCheckbox[]) => ITreeNodeCheckbox[]) => void;
  children?: ReactNode;
}

const StyledTreeItem: FC<StyledTreeItemProps> = ({ itemData, setTreeData, children }) => {
  const { name, _id: nodeId, isCheck } = itemData;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const generateTreeDataWithCheckbox = (treeData: ITreeNodeCheckbox[]) => {
      const setAllChildrenCheckbox = (tree: ITreeNodeCheckbox): ITreeNodeCheckbox => {
        return {
          ...tree,
          isCheck: checked,
          children: tree.children.map((child) => setAllChildrenCheckbox(child)),
        };
      };

      const findIdExistInChildren = (node: ITreeNodeCheckbox, nodeId: string): boolean => {
        if (node._id === nodeId) return true;
        else if (node.children.length > 0) {
          return node.children.some((item) => findIdExistInChildren(item, nodeId));
        }
        return false;
      };

      const updateIsCheckById = (node: ITreeNodeCheckbox) => {
        // handle checkbox for itself
        if (node._id === nodeId) {
          node.isCheck = checked;
          if (node.children && node.children.length > 0) {
            node.children = node.children.map((child) => setAllChildrenCheckbox(child));
          }
          // affect to its parents
        } else if (checked && findIdExistInChildren(node, nodeId)) {
          node.isCheck = true;
        }

        if (node.children && node.children.length > 0) {
          node.children = node.children.map((child) => updateIsCheckById(child));
        }
        return node;
      };

      return treeData.map((item) => updateIsCheckById(item));
    };

    setTreeData((prev: ITreeNodeCheckbox[]) => generateTreeDataWithCheckbox(prev));
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <FormControlLabel
            control={<Checkbox checked={isCheck ?? false} onChange={handleChange} />}
            label={name}
            sx={{ height: '28px' }}
          />
        </Box>
      }
      nodeId={nodeId}
      children={children}
      ContentComponent={CustomContent}
    />
  );
};

export default StyledTreeItem;
