import { Box, Typography } from '@mui/material';
import { IWeighbridgeTransactionTableResponse } from 'interfaces/erms';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CommonContainer from '../components/CommonContainer';

interface WeighbridgeTransactionTableProps {
  allWeighbridgeTransaction: IWeighbridgeTransactionTableResponse[];
}

const TotalWeighbridgeTransaction: FC<WeighbridgeTransactionTableProps> = ({ allWeighbridgeTransaction }) => {
  const { t } = useTranslation();
  return (
    <CommonContainer style={{ height: '100%', width: '320px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, textAlign: 'center' }}>
        {t('erms.total_weighbridge_transactions')}
      </Typography>
      <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
        <Typography sx={{ fontSize: '60px' }}>{allWeighbridgeTransaction.length}</Typography>
      </Box>
    </CommonContainer>
  );
};

export default TotalWeighbridgeTransaction;
