import { Box, Button, Typography } from '@mui/material';
import { validateDate, validateTimestamp } from 'common/utils/erms';
import CommonModal from 'components/Common/CommonModal';
import { QUERY_KEY } from 'constants/constants';
import { DATE_VISUALIZE_FORMAT } from 'constants/date';
import { t } from 'i18next';
import { IWeighbridgeFormInput } from 'interfaces/erms';
import moment from 'moment';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createWeighbridgeTransaction } from 'services/erms';
import FormField from '../components/CommonTextField';

interface AddTransactionProps {
  sensorId: string;
}

const AddTransaction: FC<AddTransactionProps> = ({ sensorId }) => {
  const [isOpenModalAddTransaction, setIsOpenModalAddTransaction] = useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm<IWeighbridgeFormInput>({
    defaultValues: {
      weighDate: '',
      timestamp: moment(new Date()).format('hh:mm:ss'),
      weighbridgeLocation: '',
      weighTransaction: '',
      driverName: '',
      vehicleDetails: '',
      lorryWeight: '',
      harvestWeight: '',
      netWeight: '',
    },
    mode: 'onChange',
  });

  const queryClient = useQueryClient();

  const handleOpenAddTransactionModal = () => {
    if (!sensorId) {
      toast.error('SensorId is not found');
    } else {
      setIsOpenModalAddTransaction(true);
    }
  };

  const handleCancel = () => {
    reset();
    setIsOpenModalAddTransaction(false);
  };

  const createCommentMutation = useMutation(createWeighbridgeTransaction, {
    onSuccess: () => {
      reset();
      toast.success('Create new weighbridge successful');
      queryClient.invalidateQueries([QUERY_KEY.GET_WEIGHBRIDGE_TRANSACTION_TABLE]);
      setIsOpenModalAddTransaction(false);
    },
    onError: (error) => {
      console.error('Error creating comment:', error);
    },
  });

  const handleSendTransaction = (data: IWeighbridgeFormInput) => {
    const requestData = {
      weighDate: moment(data.weighDate, DATE_VISUALIZE_FORMAT).utc(true).toISOString(),
      timestamp: data.timestamp,
      weighbridgeLocation: data.weighbridgeLocation,
      weighTransaction: data.weighTransaction,
      driverName: data.driverName,
      vehicleDetails: data.vehicleDetails,
      lorryWeight: data.lorryWeight,
      harvestWeight: data.harvestWeight,
      netWeight: data.netWeight,
      analysisId: sensorId,
    };

    createCommentMutation.mutate(requestData);
  };

  return (
    <Box sx={{ position: 'absolute', right: '4px', top: 0 }}>
      <Button onClick={handleOpenAddTransactionModal} color="primary" variant="contained" sx={{ height: '35px' }}>
        {t('erms.add_transaction')}
      </Button>
      <CommonModal
        isShow={isOpenModalAddTransaction}
        handleClose={handleCancel}
        closeIconColor="none"
        style={{ height: 'auto', width: '500px', padding: '40px', borderRadius: '10px' }}>
        <form onSubmit={handleSubmit(handleSendTransaction)}>
          <Typography sx={{ fontSize: '22px', mb: '18px' }}>{t('erms.add_weighbridge_transaction')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <Box>
              {t('erms.weigh_date')}
              <FormField
                name="weighDate"
                control={control}
                rules={{
                  required: 'This field is required',
                  validate: validateDate,
                }}
                placeholder="dd-mm-yyyy"
              />
            </Box>
            <Box>
              {t('erms.timestamp')}
              <FormField
                name="timestamp"
                control={control}
                rules={{
                  required: 'This field is required',
                  validate: validateTimestamp,
                }}
                placeholder="hh:mm:ss"
              />
            </Box>
            <Box>
              {t('erms.weighbridge_location')}
              <FormField
                name="weighbridgeLocation"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
              />
            </Box>
            <Box>
              {t('erms.transaction_number')}
              <FormField
                name="weighTransaction"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
              />
            </Box>
            <Box>
              {t('erms.driver_name')}
              <FormField
                name="driverName"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
              />
            </Box>
            <Box>
              {t('erms.vehicle_number')}
              <FormField
                name="vehicleDetails"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
              />
            </Box>
            <Box>
              {t('erms.lorry_weight_tonne')}
              <FormField
                name="lorryWeight"
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: { value: /^[0-9.]+$/, message: 'Invalid number' },
                }}
              />
            </Box>
            <Box>
              {t('erms.harvest_weight_tonne')}
              <FormField
                name="harvestWeight"
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: { value: /^[0-9.]+$/, message: 'Invalid number' },
                }}
              />
            </Box>
            <Box>
              {t('erms.net_weight_tonne')}
              <FormField
                name="netWeight"
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: { value: /^[0-9.]+$/, message: 'Invalid number' },
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '15px' }}>
              <Button type="submit" style={{ background: '#4FE3AE', color: 'white', height: '40px', width: '86px' }}>
                {t('trans.save')}
              </Button>
              <Button
                onClick={handleCancel}
                style={{ background: '#FF0506', color: 'white', height: '40px', width: '86px' }}>
                {t('trans.cancel')}
              </Button>
            </Box>
          </Box>
        </form>
      </CommonModal>
    </Box>
  );
};

export default AddTransaction;
