import { Grid, InputLabel, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FILE_TYPE, mapFileLabel } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { isValidJson } from 'common/utils/stringify';
import { ButtonCustom } from 'components/Common/ButtonCustom';
import SelectFile from 'components/Common/SelectFile';
import { QUERY_KEY } from 'constants/constants';
import { Formik } from 'formik';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createWeighbridgeAnalytic, getRasterDetails } from 'services/analytics/apiAnalyticsConfig.services';
import { ErrorsDialog } from '../ErrorDialog';

interface CommonFieldFormProps {
  sensorId: string;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const ErmsWeighbridgeForm: FC<CommonFieldFormProps> = ({ sensorId, deleteFileGeojson }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery(
    [QUERY_KEY.RASTER_DETAILS, sensorId],
    () => getRasterDetails(sensorId),
    {
      enabled: !!sensorId,
    }
  );

  const createAnalyticMutation = useMutation(createWeighbridgeAnalytic, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success(`Update Weighbridge Analytic Successful`, { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      const errorMess = e?.data?.message as string;
      let errorList = [];
      if (isValidJson(errorMess)) {
        errorList = JSON.parse(errorMess) as any[];
      } else {
        setDataErrors(errorMess);
      }
      const errors = errorList?.map((item) => ({
        error: `Row ${item.rowNumber}: ${item.errors.join(', ')}`,
      }));
      setDataErrors(errors);
    },
  });

  const initialFields = useMemo(() => {
    let initParam: any = { file: undefined };

    const uploadHistory = analyticDetails?.data?.uploadHistory;
    if (uploadHistory && Array.isArray(uploadHistory)) {
      uploadHistory.forEach((_item: any) => {
        if (_item.fileType === 'XLSX') {
          initParam.file = _item;
        }
      });
    }
    return initParam;
  }, [analyticDetails]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('dataFile', values.file || '');

          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
            onError: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid sx={{ maxWidth: '700px', width: '700px' }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.EXCEL_FILE], 'file_name'))}
                  </InputLabel>
                  <SelectFile
                    acceptFile={new RegExp(/.xlsx$/)}
                    fileSelected={values.file}
                    disabled={createAnalyticMutation.isLoading}
                    onSelectFile={(file) => {
                      setFieldValue('file', file);
                    }}
                    analysisId={sensorId}
                    fileType={FILE_TYPE.EXCEL_FILE}
                    deleteFileGeojson={deleteFileGeojson}
                  />
                </Grid>
              </Grid>
              <Grid marginLeft="auto">
                <ButtonCustom
                  border="0px"
                  colorLabel="#fff"
                  sx={{
                    width: '160px',
                    height: '1.4375em',
                  }}
                  title={t('trans.save')}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

export default ErmsWeighbridgeForm;
