import SatelliteOutlinedIcon from '@mui/icons-material/SatelliteOutlined';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, useTheme } from '@mui/material';
import {
  ClientsIcon,
  DocumentsIcon,
  FlightPlannerIcon,
  GroupIcon,
  HomeIcon,
  InventoryIcon,
  MapViewIcon,
  ScheduleIcon,
} from 'assets/icons/sidebarIcon';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { changeBreadScrumbsSelected } from 'store/slices/breadcrumbsSlice';

interface IIconNavBar {
  open: boolean;
  name: string;
  path: string;
}

export const IconNavBar = ({ open, name, path }: IIconNavBar) => {
  const dispatch: any = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(path !== '/' && location.pathname.includes(path));
  }, [location, setIsSelected, path]);

  const styles = useMemo(() => {
    return {
      listItemBtn: {
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
        mx: open ? 0 : -1,
        cursor: 'pointer',
        backgroundColor: isSelected
          ? theme.palette.mode === 'dark'
            ? theme.palette.background.paper + '99'
            : theme.palette.background.paper
          : 'transparent',
      },
      listItemIcon: {
        minWidth: 42,
        mr: open ? 3 : 'auto',
        justifyContent: 'center',
      },
      listItemText: {
        opacity: open ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
      },
    };
  }, [isSelected, open, theme.palette.background.paper, theme.palette.mode]);

  return (
    <ListItemButton
      sx={{
        ...styles.listItemBtn,
      }}
      onClick={() => {
        navigate(path);
        if (name === 'Dashboard') {
          dispatch(changeBreadScrumbsSelected('Overview'));
        } else {
          dispatch(changeBreadScrumbsSelected(''));
        }
      }}>
      <Tooltip title={t(convertTextToMultiLanguage(name, 'trans'))}>
        <ListItemIcon
          sx={{
            ...styles.listItemIcon,
          }}>
          {name === 'Dashboard' && <HomeIcon isSelected={isSelected} />}
          {name === 'Map View' && <MapViewIcon isSelected={isSelected} />}
          {name === 'Documents' && <DocumentsIcon isSelected={isSelected} />}
          {name === 'Clients' && <ClientsIcon isSelected={isSelected} />}
          {name === 'Flight Planner' && <FlightPlannerIcon isSelected={isSelected} />}
          {name === 'Schedule' && <ScheduleIcon isSelected={isSelected} />}
          {name === 'Resources' && <GroupIcon isSelected={isSelected} />}
          {name === 'Inventory' && <InventoryIcon isSelected={isSelected} />}
          {name === 'Aero Insights' && (
            <SatelliteOutlinedIcon sx={{ color: isSelected ? theme.palette.primary.main : '' }} />
          )}
          {name === 'ERMS' && (
            <ViewKanbanOutlinedIcon
              sx={{ color: isSelected ? theme.palette.primary.main : theme.palette.color.grey5 }}
            />
          )}
        </ListItemIcon>
      </Tooltip>
      <ListItemText
        primary={name}
        sx={{
          ...styles.listItemText,
          color: isSelected ? (theme) => theme.palette.primary.main : '#737373',
        }}
      />
    </ListItemButton>
  );
};
