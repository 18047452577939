import { Pagination } from '@mui/material';
import { FC } from 'react';

interface PaginationCommonProps {
  totalPage: number;
  currentPage: number;
  onChangePage: (arg: number) => void;
}

const PaginationCommon: FC<PaginationCommonProps> = ({ totalPage, currentPage, onChangePage }) => {
  return (
    <Pagination
      sx={{
        '& .MuiPagination-ul': {
          '& .Mui-selected': {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: 'white',
          },
          '& .Mui-selected:hover': {
            backgroundColor: '#23BE6AB0',
          },
          '& .MuiPaginationItem-icon': {
            color: (theme) => theme.palette.primary.main,
          },
        },
      }}
      count={totalPage ?? 1}
      defaultPage={1}
      page={currentPage}
      onChange={(_, value) => {
        onChangePage(value);
      }}
    />
  );
};

export default PaginationCommon;
