import styled from '@emotion/styled';
import { Box, Card, Grid, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { ISettingCropType, ITypeAnalysisOptions } from 'common/defines/clients';
import { TypeRaster } from 'common/defines/constants';
import { FC, useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

const CardCustom = styled(Card)(() => ({
  boxShadow: 'none',
  '.MuiCardContent-root': {
    padding: '8px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
    border: 'rgba(99, 99, 99, 0.2)',
    margin: '16px 0',
  },
}));

interface AnalysisTypeAreaProps {
  form: UseFormReturn<ISettingCropType, any>;
  typeAnalysisOptions: ITypeAnalysisOptions[];
}
const AnalysisTypeArea: FC<AnalysisTypeAreaProps> = ({ form, typeAnalysisOptions }) => {
  const { control, setValue } = form;
  const theme = useTheme();

  const listGrbOptions = typeAnalysisOptions.filter((item) => !item.type);
  const listErmsOptions = typeAnalysisOptions.filter((item) => item.type === TypeRaster.ERMS);

  const handleSelect = useCallback(
    (event: any, name: any) => {
      setValue(name, event.target.value);
    },
    [setValue]
  );

  const listData = [
    { id: 1, typeLabel: 'RGB', listData: listGrbOptions, name: 'rgbList', autoCreatedName: 'isAutoCreateAnalysisRGB' },
    {
      id: 2,
      typeLabel: 'RGB MS',
      listData: listGrbOptions,
      name: 'rgbMsList',
      autoCreatedName: 'isAutoCreateAnalysisRgbMs',
    },
    {
      id: 3,
      typeLabel: 'ERMS',
      listData: listErmsOptions,
      name: 'ermsList',
      autoCreatedName: 'isAutoCreateAnalysisErms',
    },
  ];

  return (
    <>
      {listData.map((item) => (
        <CardCustom key={item.id}>
          <Box
            sx={{
              pb: '12px',
              background: theme.palette.background.paper,
            }}>
            <InputLabel sx={{ color: theme.palette.primary.main }}>{item.typeLabel}</InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  name={item.name as any}
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="clientId">
                      <InputLabel>Analysis Type</InputLabel>
                      <Select
                        sx={{
                          height: '46px',
                          padding: '0px 14px',
                          width: '100%',
                          maxWidth: '680.61px',
                        }}
                        fullWidth
                        multiple
                        {...field}
                        onChange={(event) => handleSelect(event, item.name)}>
                        {item?.listData ? (
                          item.listData?.map((data) => (
                            <MenuItem key={data.id} value={data.value}>
                              {data.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Raster Found</MenuItem>
                        )}
                      </Select>
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={item.autoCreatedName as any}
                  control={control}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mb: '10px',
                      }}
                      key="clientId">
                      <InputLabel>Auto Created Analysis</InputLabel>
                      <Select
                        sx={{
                          height: '46px',
                          padding: '0px 14px',
                          width: '100%',
                          maxWidth: '680.61px',
                        }}
                        fullWidth
                        multiple
                        {...field}
                        onChange={(event) => handleSelect(event, item.autoCreatedName)}
                        // renderValue={(selected) => handleRenderRasterList(selected)}>
                      >
                        {item?.listData ? (
                          item.listData?.map((data) => (
                            <MenuItem key={data.id} value={data.value}>
                              {data.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Raster Found</MenuItem>
                        )}
                      </Select>
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </CardCustom>
      ))}
    </>
  );
};

export default AnalysisTypeArea;
