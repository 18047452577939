import { Box, Button, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeFilterDateRange, ermsSelector } from 'store/slices/ermsSlice';

interface SelectPeriodProps {
  onApplyDate: () => void;
}

const SelectPeriod: FC<SelectPeriodProps> = ({ onApplyDate }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { filterDate } = useAppSelector(ermsSelector);

  const handleStartDateChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(
      changeFilterDateRange({
        ...filterDate,
        startDate: event.target.value,
      })
    );
  };

  const handleEndDateChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(
      changeFilterDateRange({
        ...filterDate,
        endDate: event.target.value,
      })
    );
  };

  return (
    <Box
      sx={{
        gap: '16px',
        padding: '10px',
        display: 'flex',
        borderRadius: '8px',
        alignItems: 'center',
      }}>
      <Typography variant="body1" fontWeight="bold">
        {t('erms.select_period')}
      </Typography>

      <TextField
        type="date"
        value={filterDate.startDate}
        defaultValue={filterDate.startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{ shrink: true }}
        sx={{
          width: 120,
          '& .MuiInputBase-input': {
            padding: '7.5px 14px',
          },
        }}
      />

      <Typography variant="body1">{t('erms.to')}</Typography>

      <TextField
        type="date"
        value={filterDate.endDate}
        defaultValue={filterDate.endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{ shrink: true }}
        sx={{
          width: 120,
          '& .MuiInputBase-input': {
            padding: '7.5px 14px',
          },
          '& .MuiSvgIcon-root': {
            color: 'red',
          },
        }}
      />

      <Button color="primary" variant="contained" sx={{ height: '35px' }} onClick={onApplyDate}>
        {t('trans.apply')}
      </Button>
    </Box>
  );
};

export default SelectPeriod;
