import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface CommonContainerProps {
  children: ReactNode;
  style?: SxProps;
}

const CommonContainer: FC<CommonContainerProps> = ({ children, style }) => {
  return (
    <Box sx={{ ...style, backgroundColor: (theme) => theme.palette.color.backdrop, borderRadius: 2, p: 2 }}>
      {children}
    </Box>
  );
};

export default CommonContainer;
