import { Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { IWeighbridgeTransactionTableResponse } from 'interfaces/erms';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CommonContainer from '../components/CommonContainer';

interface WeighBridgeTransactionDistributionOverTimeProps {
  allWeighbridgeTransaction: IWeighbridgeTransactionTableResponse[];
}

const WeighBridgeTransactionDistributionOverTime: FC<WeighBridgeTransactionDistributionOverTimeProps> = ({
  allWeighbridgeTransaction,
}) => {
  const { t } = useTranslation();
  const chartData = React.useMemo(() => {
    const dateCounts: Record<string, number> = {};

    allWeighbridgeTransaction.forEach((transaction) => {
      const weighDate = moment(transaction.weighDate).format('DD-MM-YYYY');
      if (weighDate) {
        dateCounts[weighDate] = (dateCounts[weighDate] || 0) + 1;
      }
    });

    return Object.entries(dateCounts)
      .map(([name, value]) => ({ name, value }))
      .sort(
        (a, b) => moment(a.name, 'DD-MM-YYYY').toDate().getTime() - moment(b.name, 'DD-MM-YYYY').toDate().getTime()
      );
  }, [allWeighbridgeTransaction]);

  const chartOptions = {
    grid: {
      bottom: 80,
    },
    xAxis: {
      type: 'category',
      data: chartData.map((d) => d.name),
      axisLabel: {
        rotate: 90,
      },
    },
    yAxis: {
      type: 'value',
      name: t('erms.number_of_weighbridge_transaction'),
      nameLocation: 'middle',
      nameGap: 50,
      nameTextStyle: {
        fontSize: 15,
        fontWeight: 500,
      },
    },
    series: [
      {
        data: chartData.map((d) => d.value),
        type: 'line',
      },
    ],
  };

  return (
    <CommonContainer>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, textAlign: 'center' }}>
        {t('erms.weighbridge_transaction_distribution')}
      </Typography>
      <ReactECharts option={chartOptions} style={{ height: 400 }} />
    </CommonContainer>
  );
};

export default WeighBridgeTransactionDistributionOverTime;
