import { Box } from '@mui/material';
import { IOption, ITreeNodeCheckbox } from 'interfaces/erms';
import { FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import UnlinkedHarvestTransactionTable from './UnlinkedHarvestTransactionTable';
import WeighbridgeTransactionTable from './WeighbridgeTransactionTable';

interface UnlinkedTransactionProps {
  treeData: ITreeNodeCheckbox[];
}

const UnlinkedTransaction: FC<UnlinkedTransactionProps> = ({ treeData }) => {
  const [listTagOptions, setListTagOptions] = useState<IOption[]>([]);

  return (
    <Scrollbars autoHeightMin="100%">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <WeighbridgeTransactionTable treeData={treeData} setListTagOptions={setListTagOptions} />
        <UnlinkedHarvestTransactionTable treeData={treeData} listTagOptions={listTagOptions} />
      </Box>
    </Scrollbars>
  );
};

export default UnlinkedTransaction;
