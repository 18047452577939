import { Box } from '@mui/material';
import { ITreeNodeCheckbox, TransactionModeEnum } from 'interfaces/erms';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changeTriggerCallTransactionTable } from 'store/slices/ermsSlice';
import SelectLocation from './select-location';
import LinkedTransaction from './weighbridge-main-page/linked-transaction';
import SelectTransaction from './weighbridge-main-page/select-transaction';
import SelectPeriod from './weighbridge-main-page/SelectPeriod';
import UnlinkedTransaction from './weighbridge-main-page/unlinked-transaction';

const Weighbridge = () => {
  const [treeData, setTreeData] = useState<ITreeNodeCheckbox[]>([]);
  const [transactionMode, setTransactionMode] = useState<TransactionModeEnum>(TransactionModeEnum.UNLINKED);
  const dispatch = useAppDispatch();

  const callAllApi = () => {
    dispatch(
      changeTriggerCallTransactionTable({
        isCallUnlinkedHarvestTransactionTable: true,
        isCallWeighbridgeTransactionTable: true,
        isCallLinkedHarvestTransactionTable: true,
        isCallAllWeighbridgeTransaction: true,
      })
    );
  };

  const onClickView = () => {
    callAllApi();
  };

  const onApplyDate = () => {
    callAllApi();
  };

  return (
    <Box display="flex" flexDirection="row" width="100%">
      <SelectLocation treeData={treeData} setTreeData={setTreeData} onClickView={onClickView} />
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          width: '100%',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}>
        <SelectPeriod onApplyDate={onApplyDate} />
        <SelectTransaction transactionMode={transactionMode} setTransactionMode={setTransactionMode} />

        {transactionMode === TransactionModeEnum.UNLINKED ? (
          <UnlinkedTransaction treeData={treeData} />
        ) : (
          <LinkedTransaction treeData={treeData} />
        )}
      </Box>
    </Box>
  );
};

export default Weighbridge;
