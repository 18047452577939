import { IMetadata } from 'common/defines/clients';

export interface ITreeNode {
  _id: string;
  name: string;
  parent: string | null;
  isLastLevel: boolean;
  children: ITreeNode[];
}

export interface ITreeNodeCheckbox extends ITreeNode {
  isCheck: boolean;
  children: ITreeNodeCheckbox[] | [];
}

export enum TransactionModeEnum {
  UNLINKED = 'UNLINKED',
  LINKED = 'LINKED',
}

export interface Link {
  _id: string;
  harvestId: string;
  tag: string;
  weighbridgeId: string;
  weighbridgeNetWeight: number;
}

export interface IHarvestResponseData {
  _id: string;
  analysisId: string;
  hvDate: Date;
  hvId: string;
  estateName: string;
  divisionName: string;
  blockName: string;
  inputBy: string;
  workerId: string;
  hvRipe: number;
  hvOverripe: number;
  hvUnripe: number;
  hvUnderripe: number;
  hvRotten: number;
  hvLongstalk: number;
  hvEmpty: number;
  hvRatdamage: number;
  hvTotal: number;
  hvLoosefr: number;
  paidBlock: number;
  driverName: string;
  vehicleNumber: string;
  groupName: string;
  linkStatus: string;
  links: Link[];
}

export interface ILinkedHarvestTableData extends IHarvestResponseData {
  paidFFB: number;
  averageWeight: number;
  totalWeight: number;
}

export interface ISummaryHarvestByWeighbridgeId {
  weighbridgeId: string;
  totalFFB: number;
}

export interface IHarvestTransactionResponse {
  docs: IHarvestResponseData[];
  metadata: IMetadata;
  summaryHarvestByWeighbridgeId: ISummaryHarvestByWeighbridgeId[];
}

export interface IHarvestResponse {
  levelId: string;
  data: IHarvestResponseData[];
}

export enum FormulaModeEnum {
  SUM = 'SUM',
  LIST = 'LIST',
  NONE = 'NONE',
}

export interface ITreeNodeCheckboxHarvest extends ITreeNodeCheckbox {
  harvestData: IHarvestResponseData[];
  children: ITreeNodeCheckboxHarvest[];
  formulaMode?: FormulaModeEnum;
}

export interface IFilterDate {
  startDate: string;
  endDate: string;
}

export interface IWeighbridgeTransactionTableResponse {
  _id: string;
  analysisId: string;
  weighDate: Date;
  timestamp: string;
  weighbridgeLocation: string;
  weighTransaction: string;
  vehicleDetails: string;
  lorryWeight: number;
  harvestWeight: number;
  netWeight: number;
  driverName: string;
}

export interface IWeighbridgeTransactionTableDisplay extends IWeighbridgeTransactionTableResponse {
  tags: string;
}

export interface IWeighbridgeFormInput
  extends Pick<
    IWeighbridgeTransactionTableResponse,
    'weighbridgeLocation' | 'weighTransaction' | 'vehicleDetails' | 'driverName' | 'timestamp'
  > {
  weighDate: string;
  lorryWeight: string;
  harvestWeight: string;
  netWeight: string;
}
export interface IOption {
  label: string;
  value: string;
}

export enum LinkStatusEnum {
  LINK = 'LINK',
  UNLINK = 'UNLINK',
}

export interface IUnlinkHarvestTransactionMutateData {
  fromDate: string;
  toDate: string;
  currentPage: number;
  levelIds: string[];
  linkStatus: LinkStatusEnum;
  perPage: number;
}

export type IWeighbridgeTransactionMutateData = Omit<IUnlinkHarvestTransactionMutateData, 'linkStatus'>;

export interface ICreateHarvestLinkRequest {
  harvestId: string;
  tag: string;
  weighbridgeId: string;
}
export type IWeighbridgeTransactionDistributeData = Pick<IUnlinkHarvestTransactionMutateData, 'levelIds'>;
