import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FC } from 'react';
import { IListMenuItem } from '.';

interface LeftMenuProps {
  listMenuItem: IListMenuItem[];
  selectedIndex: number;
  setSelectedIndex: (arg: number) => void;
}

const LeftMenu: FC<LeftMenuProps> = ({ listMenuItem, selectedIndex, setSelectedIndex }) => {
  const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.color.label1,
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      opacity: 0.8,
    },
  }));

  return (
    <Box sx={{ width: '100%', maxWidth: '200px', bgcolor: 'background.paper' }}>
      <nav aria-label="menu">
        <List
          sx={{
            paddingTop: '0px',
          }}>
          {listMenuItem.map((item) => (
            <ListItem key={item.key} disablePadding>
              <ListItemButton selected={selectedIndex === item.key} onClick={() => setSelectedIndex(item.key)}>
                <CustomListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </nav>
    </Box>
  );
};

export default LeftMenu;
