import { TextField } from '@mui/material';
import { IWeighbridgeFormInput } from 'interfaces/erms';
import React from 'react';
import { Control, Controller, Path, RegisterOptions } from 'react-hook-form';

interface FormFieldProps {
  name: Path<IWeighbridgeFormInput>;
  control: Control<IWeighbridgeFormInput>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  placeholder?: string;
}

const FormField: React.FC<FormFieldProps> = ({ name, control, rules, placeholder }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          defaultValue={field.value || ''}
          id="outlined-start-adornment"
          placeholder={placeholder}
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default FormField;
