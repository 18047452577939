import { Typography } from '@mui/material';
import { generateListCheckedLastLevelIds } from 'common/utils/erms';
import { QUERY_KEY } from 'constants/constants';
import ReactECharts from 'echarts-for-react';
import { ITreeNodeCheckbox } from 'interfaces/erms';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getWeighbridgeTransactionDistribuitionTable } from 'services/erms';
import CommonContainer from '../components/CommonContainer';

interface WeighbridgeTransactionDistributionProps {
  treeData: ITreeNodeCheckbox[];
}

const WeighbridgeTransactionDistribution: FC<WeighbridgeTransactionDistributionProps> = ({ treeData }) => {
  const { t } = useTranslation();
  const getListCheckedLastLevelIds = generateListCheckedLastLevelIds(treeData);
  const listHighestLevelIds = treeData.map((item) => (item.isCheck ? item._id : '')).filter((item) => item);

  const { data, isSuccess } = useQuery(
    [QUERY_KEY.GET_ALL_WEIGHBRIDGE_TRANSACTION, listHighestLevelIds],
    () => getWeighbridgeTransactionDistribuitionTable({ levelIds: getListCheckedLastLevelIds }),
    {
      enabled: !!listHighestLevelIds.length,
    }
  );

  const chartData = useMemo(() => {
    if (!isSuccess || !data) return { categories: [], values: [] };

    const categories = data.map(
      (item: any) => `${item._id.estateName} - ${item._id.divisionName} - ${item._id.blockName}`
    );
    const values = data.map((item: any) => item.count);

    return { categories, values };
  }, [data, isSuccess]);

  const chartOptions = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: chartData.categories,
        axisLabel: {
          margin: 10,
          formatter: function (value: string) {
            const maxLength = 20;
            const lines = value.match(new RegExp(`.{1,${maxLength}}`, 'g')) || [];
            return lines.join('\n');
          },
        },
      },
      yAxis: {
        type: 'value',
        name: t('erms.number_of_weighbridge_transaction'),
        nameLocation: 'middle',
        nameGap: 50,
        nameTextStyle: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
      series: [
        {
          type: 'bar',
          data: chartData.values,
          barWidth: '50%',
        },
      ],
    };
  }, [chartData]);

  return (
    <CommonContainer>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, textAlign: 'center' }}>
        {t('erms.weighbridge_transaction_distribution_by_location')}
      </Typography>
      <ReactECharts option={chartOptions} style={{ height: 400 }} />
    </CommonContainer>
  );
};

export default WeighbridgeTransactionDistribution;
