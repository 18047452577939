import { useTheme } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { TransactionModeEnum } from 'interfaces/erms';
import { FC } from 'react';

interface TransactionButtonCommonProps {
  selected: boolean;
  value: TransactionModeEnum;
  label: string;
  onClick: () => void;
}

const TransactionButtonCommon: FC<TransactionButtonCommonProps> = ({ value, selected, label, onClick }) => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;

  return (
    <ToggleButton
      value={value}
      selected={selected}
      onChange={() => {
        onClick();
      }}
      sx={{
        width: '50%',
        backgroundColor: selected ? mainColor : 'default',
        color: selected ? 'white' : 'default',
        '&.Mui-selected': {
          backgroundColor: mainColor,
          color: 'white',
          '&:hover': {
            backgroundColor: mainColor,
          },
        },
      }}>
      {label}
    </ToggleButton>
  );
};

export default TransactionButtonCommon;
