import VerticalAlignBottomSharpIcon from '@mui/icons-material/VerticalAlignBottomSharp';
import { Box, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { HarvestConfigurationEnum } from 'common/defines/clients';
import { FILE_TYPE, mapFileLabel } from 'common/defines/constants';
import { convertTextToMultiLanguage } from 'common/utils/convert';
import { isValidJson } from 'common/utils/stringify';
import { Formik } from 'formik';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QUERY_KEY } from '../../../../../constants/constants';
import { createHarvestAnalytic, getRasterDetails } from '../../../../../services/analytics/apiAnalyticsConfig.services';
import { ButtonCustom } from '../../../../Common/ButtonCustom';
import SelectFile from '../../../../Common/SelectFile';
import { ErrorsDialog } from '../ErrorDialog';

interface ErmsHarvestFormProps {
  sensorId: string;
  deleteFileGeojson: (id: string) => Promise<AxiosResponse<any, any>>;
}

const ErmsHarvestForm: FC<ErmsHarvestFormProps> = ({ sensorId, deleteFileGeojson }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formRef = useRef<any>();
  const theme = useTheme();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [dataErrors, setDataErrors] = useState(null as any);

  const downloadList = [
    { key: 1, value: '1M', label: '1M' },
    { key: 2, value: '3M', label: '3M' },
    { key: 3, value: '6M', label: '6M' },
    { key: 4, value: 'YTD', label: 'YTD' },
    { key: 5, value: 'MTD', label: 'MTD' },
  ];

  const configurationList = [
    { key: 1, value: HarvestConfigurationEnum.METHOD_A, label: 'Method A' },
    { key: 2, value: HarvestConfigurationEnum.METHOD_B, label: 'Method B' },
  ];

  const closeErrorModal = () => {
    setOpenErrorModal(false);
  };

  const { data: analyticDetails, refetch } = useQuery([QUERY_KEY.RASTER_DETAILS, sensorId], () =>
    getRasterDetails(sensorId)
  );

  const createAnalyticMutation = useMutation(createHarvestAnalytic, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY.ANALYSIS_UPLOAD_HISTORY);
      refetch();
      toast.success(`Update Harvard Analytic Successful`, { toastId: 1 });
    },
    onError: (e: any) => {
      setOpenErrorModal(true);
      const errorMess = e?.data?.message as string;
      let errorList = [];
      if (isValidJson(errorMess)) {
        errorList = JSON.parse(errorMess) as any[];
      } else {
        setDataErrors(errorMess);
      }
      const errors = errorList?.map((item) => ({
        error: `Row ${item.rowNumber}: ${item.errors.join(', ')}`,
      }));
      setDataErrors(errors);
    },
  });

  useEffect(() => {
    formRef.current && formRef.current.resetForm();
  }, [sensorId, formRef]);

  const initialFields = useMemo(() => {
    let initParam: any = { file: undefined, configuration: undefined };

    initParam.configuration = analyticDetails?.data?.configuration ?? HarvestConfigurationEnum.METHOD_A;
    const uploadHistory = analyticDetails?.data?.uploadHistory;
    if (uploadHistory && Array.isArray(uploadHistory)) {
      uploadHistory.forEach((_item: any) => {
        if (_item.fileType === 'XLSX') {
          initParam.file = _item;
        }
      });
    }
    return initParam;
  }, [analyticDetails]);

  const handleDownloadConditionTime = (value: string) => {};

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={Object.assign(initialFields)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          formData.append('analysisId', sensorId);
          formData.append('configuration', values.configuration);
          formData.append('dataFile', values.file || '');

          for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
          }

          createAnalyticMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
            },
            onError: () => {
              resetForm();
            },
          });
        }}>
        {({ values, setFieldValue, handleSubmit, submitForm, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Grid display="flex" width="100%">
              <Grid
                sx={{
                  maxWidth: '700px',
                  width: '700px',
                }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t(convertTextToMultiLanguage(mapFileLabel[FILE_TYPE.EXCEL_FILE], 'file_name'))}
                  </InputLabel>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <SelectFile
                      acceptFile={new RegExp(/.xlsx$/)}
                      fileSelected={values.file}
                      disabled={createAnalyticMutation.isLoading}
                      onSelectFile={(file) => {
                        setFieldValue('file', file);
                      }}
                      analysisId={sensorId}
                      fileType={FILE_TYPE.EXCEL_FILE}
                      deleteFileGeojson={deleteFileGeojson}
                    />
                    {!!values?.file && (
                      <Box
                        sx={{
                          mt: '8px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          pr: '40px',
                        }}>
                        {downloadList.map((item) => (
                          <Box component="span" key={item.key} display="flex" flexDirection="row">
                            <VerticalAlignBottomSharpIcon
                              color="primary"
                              sx={{ cursor: 'pointer', opacity: 0.8 }}
                              onClick={() => handleDownloadConditionTime(item.value)}
                            />
                            <Typography component="span">{item.label}</Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'}>
                <ButtonCustom
                  title={t('trans.save')}
                  border="0px"
                  colorLabel="#FFF"
                  sx={{ width: '160px', height: '1.4375em' }}
                  backgroundColor={theme.palette.primary.main}
                  onClick={() => submitForm()}
                  disabled={createAnalyticMutation.isLoading}
                  loading={createAnalyticMutation.isLoading}
                />
              </Grid>
            </Grid>
            <Grid display="flex" width="100%">
              <Grid
                sx={{
                  maxWidth: '700px',
                  width: '700px',
                }}>
                <Grid display="flex" alignItems="center" mt={2} justifyContent="space-between">
                  <InputLabel variant="standard" sx={{ fontSize: '14px', margin: 0 }} htmlFor="uncontrolled-native">
                    {t('trans.configuration')}
                  </InputLabel>
                  <Select
                    id="configuration"
                    name="configuration"
                    value={values.configuration}
                    onChange={handleChange}
                    sx={{ width: '350px' }}
                    size="small"
                    displayEmpty>
                    {configurationList.map((item) => (
                      <MenuItem key={item.key} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid marginLeft={'auto'} sx={{ minWidth: '160px' }}></Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ErrorsDialog
        title="Error Upload"
        open={openErrorModal}
        onClose={closeErrorModal}
        data={dataErrors}
        onCancel={closeErrorModal}
        submit={false}
      />
    </div>
  );
};

export default ErmsHarvestForm;
