import { Box } from '@mui/material';
import { IMetadata } from 'common/defines/clients';
import { QUERY_KEY } from 'constants/constants';
import { ITreeNodeCheckbox, IWeighbridgeTransactionTableResponse } from 'interfaces/erms';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery } from 'react-query';
import { getWeighbridgeTransactionTable } from 'services/erms';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeTriggerCallTransactionTable, ermsSelector } from 'store/slices/ermsSlice';
import LinkedHarvestTransactionTable from './LinkedHarvestTransactionTable';
import TotalWeighbridgeTransaction from './TotalWeighbridgeTransaction';
import WeighbridgeLocation from './WeighbridgeLocation';
import WeighBridgeTransactionDistributionOverTime from './WeighBridgeTransactionDistributionOverTime';
import WeighbridgeTransactionDistribution from './WeighbridgeTrasactionDistributionByLocation';

interface UnlinkedTransactionProps {
  treeData: ITreeNodeCheckbox[];
}

const LinkedTransaction: FC<UnlinkedTransactionProps> = ({ treeData }) => {
  const [allWeighbridgeTransaction, setAllWeighbridgeTransaction] = useState<IWeighbridgeTransactionTableResponse[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { filterDate, trigger } = useAppSelector(ermsSelector);
  const dispatch = useAppDispatch();

  const listHighestLevelIds = treeData.map((item) => (item.isCheck ? item._id : '')).filter((item) => item);

  const weighbridgeTransactionRequest = {
    fromDate: moment(filterDate.startDate).utc(true).toISOString(),
    toDate: moment(filterDate.endDate).utc(true).toISOString(),
    currentPage: currentPage,
    levelIds: listHighestLevelIds,
    perPage: 50,
  };

  const { refetch: refetchAllWeighbridge } = useQuery(
    [QUERY_KEY.GET_ALL_WEIGHBRIDGE_TRANSACTION, currentPage],
    () => getWeighbridgeTransactionTable(weighbridgeTransactionRequest),
    {
      enabled: !!listHighestLevelIds.length,
      retry: 0,
      onSuccess(res) {
        const metadata = res?.metadata as IMetadata;
        const { nextPage } = metadata;
        if (nextPage) {
          setCurrentPage((prev) => prev + 1);
        }
        if (currentPage === 1) {
          setAllWeighbridgeTransaction(res?.data);
        } else {
          setAllWeighbridgeTransaction([...allWeighbridgeTransaction, ...res?.data]);
        }
      },
      onError() {
        setAllWeighbridgeTransaction([]);
      },
    }
  );

  useEffect(() => {
    const { isCallAllWeighbridgeTransaction } = trigger;
    if (isCallAllWeighbridgeTransaction) {
      setCurrentPage(1);

      refetchAllWeighbridge();
      dispatch(changeTriggerCallTransactionTable({ isCallAllWeighbridgeTransaction: false }));
    }
  }, [dispatch, refetchAllWeighbridge, trigger]);

  return (
    <Scrollbars height="100%" autoHide>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', height: '300px', gap: 2 }}>
          <TotalWeighbridgeTransaction allWeighbridgeTransaction={allWeighbridgeTransaction} />
          <WeighbridgeLocation allWeighbridgeTransaction={allWeighbridgeTransaction} />
        </Box>
        <WeighBridgeTransactionDistributionOverTime allWeighbridgeTransaction={allWeighbridgeTransaction} />
        <WeighbridgeTransactionDistribution treeData={treeData} />
        <LinkedHarvestTransactionTable treeData={treeData} />
      </Box>
    </Scrollbars>
  );
};

export default LinkedTransaction;
