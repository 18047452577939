import { Box } from '@mui/material';
import { TransactionModeEnum } from 'interfaces/erms';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TransactionButtonCommon from './TransactionButtonCommon';

interface SelectTransactionProps {
  transactionMode: TransactionModeEnum;
  setTransactionMode: (arg: TransactionModeEnum) => void;
}

const SelectTransaction: FC<SelectTransactionProps> = ({ transactionMode, setTransactionMode }) => {
  const { t } = useTranslation();
  const listButtons = [
    { key: TransactionModeEnum.UNLINKED, label: t('erms.unlinked_transaction') },
    { key: TransactionModeEnum.LINKED, label: t('erms.linked_transaction') },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        {listButtons.map((item) => (
          <TransactionButtonCommon
            key={item.key}
            value={item.key}
            selected={transactionMode === item.key}
            onClick={() => setTransactionMode(item.key)}
            label={item.label}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SelectTransaction;
