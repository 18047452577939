import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeView from '@mui/lab/TreeView';
import { Box, Button } from '@mui/material';
import { generateListAllIds, generateTreeDataWithCheckbox } from 'common/utils/erms';
import { QUERY_KEY } from 'constants/constants';
import { ITreeNode, ITreeNodeCheckbox } from 'interfaces/erms';
import { FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getLevelListByCropType } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import StyledTreeItem from './components/StyledTreeItem';
import SelectCropType from './SelectCropType';

interface SelectLocationProps {
  treeData: ITreeNodeCheckbox[];
  setTreeData: (update: (prev: ITreeNodeCheckbox[]) => ITreeNodeCheckbox[]) => void;
  onClickView: () => void;
}

const SelectLocation: FC<SelectLocationProps> = ({ treeData, setTreeData, onClickView }) => {
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { cropType } = useAppSelector(mapViewSelector);

  useQuery([QUERY_KEY.LEVEL_LIST, cropType], () => getLevelListByCropType(clientId, cropType), {
    enabled: !!cropType && !!clientId,
    onSuccess(res) {
      const data = res as ITreeNode[];
      setTreeData(() => generateTreeDataWithCheckbox(data));

      const allIds = generateListAllIds(data);
      setExpandedNodes(allIds);
    },
  });

  const renderRecursiveTreeItem = (item: ITreeNodeCheckbox) => {
    return (
      <StyledTreeItem
        key={item._id}
        itemData={item}
        setTreeData={setTreeData}
        children={!!item.children?.length ? item.children.map((ele) => renderRecursiveTreeItem(ele)) : undefined}
      />
    );
  };

  return (
    <Box
      sx={{
        width: '300px',
        minWidth: '300px',
        border: '1px solid #CACED3',
        borderWidth: '0px 1px',
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
      <Scrollbars autoHeight autoHeightMin="calc(100vh - 64px)">
        <SelectCropType />
        <TreeView
          aria-label="select_location"
          defaultExpanded={['3']}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          onNodeToggle={(_, nodeIds) => {
            setExpandedNodes(nodeIds);
          }}
          expanded={expandedNodes}>
          {treeData.map((item) => renderRecursiveTreeItem(item))}
        </TreeView>
        <Box sx={{ mt: '24px', display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" sx={{ width: '40%' }} onClick={onClickView}>
            {t('erms.view')}
          </Button>
        </Box>
      </Scrollbars>
    </Box>
  );
};

export default SelectLocation;
