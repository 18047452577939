import { ITreeNode, ITreeNodeCheckbox } from 'interfaces/erms';
import moment from 'moment';

export const generateListAllIds = (treeData: ITreeNode[]) => {
  const getAllIds = (nodes: ITreeNode[]): string[] => {
    return nodes.reduce<string[]>((acc, node) => {
      acc.push(node._id);
      if (node.children && node.children.length > 0) {
        acc = [...acc, ...getAllIds(node.children)];
      }
      return acc;
    }, []);
  };
  return getAllIds(treeData);
};

export const generateTreeDataWithCheckbox = (treeData: ITreeNode[]) => {
  const addUnselectAllCheckbox = (item: ITreeNode): ITreeNodeCheckbox => {
    const { _id, parent, name, isLastLevel } = item;
    const chosenItem = { _id, parent, name, isLastLevel };
    if (!!item.children?.length) {
      return {
        ...chosenItem,
        isCheck: true,
        children: item.children.map((ele) => addUnselectAllCheckbox(ele)),
      };
    }
    return { ...chosenItem, isCheck: true, children: [] };
  };

  return treeData.map((item) => addUnselectAllCheckbox(item));
};

export const generateListCheckedLastLevelIds = (treeData: ITreeNodeCheckbox[]) => {
  const listIds: string[] = [];
  const getListCheckedLastLevelIds = (node: ITreeNodeCheckbox) => {
    if (node.isLastLevel && node.isCheck) {
      listIds.push(node._id);
    } else {
      if (node.children.length > 0) {
        node.children.forEach((item) => getListCheckedLastLevelIds(item));
      }
    }
  };
  treeData.forEach((node) => getListCheckedLastLevelIds(node));

  return listIds;
};

export const validateDate = (value: string) => {
  return moment(value, 'DD-MM-YYYY', true).isValid();
};

export const validateTimestamp = (value: string) => {
  return moment(value, 'HH:mm:ss', true).isValid();
};
